import React from 'react';
import { useField } from 'formik';
import LazyImage from './lazyImage';
import clsx from 'clsx';

export const MultipleChoiceElement = ({ image, text, fieldName }) => {
	const [field, , helpers] = useField(fieldName);
	const { setValue } = helpers;

	const handleClick = () => {
		if(field.value === text) {
			setValue('');
		} else {
			setValue(text);
		}
	};

	return (
		<div onClick={handleClick} className="relative col-span-2" >
			<div className="multiplechoice-element">
				<div className={clsx('overflow-hidden rounded-none multiplechoice-element__image-holder', {'rounded-xl ': (field.value === text) })}>
					<LazyImage image={image} className="multiplechoice-element__image"/>
					<div className={clsx('multiplechoice-element--border', {'multiplechoice-element--border__active': (field.value === text)})} >
						<div className="multiplechoice-element--border--top"></div>
						<div className="multiplechoice-element--border--bottom"></div>
						<div className="multiplechoice-element--border--left"></div>
						<div className="multiplechoice-element--border--right"></div>
					</div>
				</div>
				<span className="mt-16 font-nove text-20">{text}</span>
			</div>
		</div>
	);
};
