import React, { useContext } from 'react';
import { Text } from './';
import GlobalContext from '../context/global-context';
import { FaceSad, KenyaMapIcon } from './svgs';

export const MobileWarning = () => {
	const ctx = useContext(GlobalContext);

	return <div className="items-center justify-center mobile-warning">
		<div className="w-full max-w-md px-40 mx-auto text-center">
			<div className="mx-40">
				<FaceSad/>
			</div>
			<div className="mx-40 mb-40 mt-51">
				<KenyaMapIcon fill="#ffffff"/>
			</div>
			<Text className="mt-40 text-white-100" type="body1">{ctx.tr('mobileErrorMessage')}.</Text>
		</div>
	</div>;
};
