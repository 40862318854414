import React, { useContext, useEffect, useState } from 'react';
import { Text } from './typography';
import { SunOutline } from './svgs';
import { Field, useField, useFormikContext } from 'formik';
import GlobalContext from '../context/global-context';

const Subject = ({ children, theme }) => {
	const { values } = useFormikContext();
	const [field, , helpers] = useField('tema');

	const handleClick = e => {
		if(e.target.value === field.value) helpers.setValue('');
	};

	const handleChange = e => {
		helpers.setValue(e.target.value);
	};

	return (
		<label className="relative my-24 cursor-pointer">
			<Field
				type="radio"
				value={theme.name}
				name="tema"
				onChange={handleChange}
				onClick={handleClick}
				className="absolute opacity-0"
			/>
			<div className="flex items-center">
				<SunOutline fill={`${values.tema === theme.name ? '#fff' : 'none'}`} />
				<Text type="subheading1" className="ml-4 text-white-100">{children}</Text>
			</div>
		</label>
	);
};

export const Subjects = () => {
	const context = useContext(GlobalContext);
	const [themes, setThemes ] = useState([]);

	useEffect(() => {
		setThemes(context.getThemes());
	}, []);
	
	return (
		<div className="grid gap-24 grid-cols-3-max">
			{themes.map((theme) => (
				<Subject key={theme.name} theme={theme}>{theme.value}</Subject>
			))}
		</div>
	);
};
