import React from 'react';
import clsx from 'clsx';

import LazyImage from './lazyImage';
import { CheckMark } from './svgs';

export const AnswerMultipleChoiceElement = ({ userAnswer, correct, ...args }) => {
	const { text, image } = { ...args };
	return (
		<div className="multiplechoice-answer">
			<div className={clsx('multiplechoice-answer__imageholder', {'multiplechoice-answer__imageholder--user': (userAnswer === text) })}>
				<LazyImage image={image} className="multiplechoice-answer__image"/>
			</div>
			<span className="mt-12 text-black font-nove text-20">{text}</span>
			{correct === text && <div className="multiplechoice-answer__checkmark"><CheckMark /></div>}
		</div>
	);
};
