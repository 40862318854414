import React, { useContext, useEffect, useState } from 'react';
import { Text } from './';
import { Face } from './face';
import { DenmarkFlag, KenyaFlag, ScrollDownArch } from './svgs';
import GlobalContext from '../context/global-context';
import useLocalStorage from './../hooks/useLocalstorage';
import { AnswerMultipleChoiceElement } from './answerMultipleChoiceElement';

export const AnswerMultipleChoice = ({ blok, fullSlug, questionElements = undefined}) => {
	const [ storedValue ] = useLocalStorage('FKN_Questions');
	const [ answers, setAnswers ] = useState(null);
	const [ questionElementsArr, setQuestionElements] = useState([]);
	const context = useContext(GlobalContext);

	useEffect(() => {
		setAnswers(storedValue[fullSlug]);
		if(questionElements !== undefined && questionElements !== null) {
			const questionElArr = Object.entries(questionElements).filter(el => el[0] !== 'key').map(q => q[1]);
			setQuestionElements(questionElArr);
		}
	}, []);

	return (
		<section className="relative flex items-center min-h-screen py-34 bg-secondary">
			<div className="grid grid-cols-12 mx-auto w-92p max-w-grid">
				<div className="col-start-1 col-end-9 text-primary">
					<Text type="heading2" className='mb-36' >{blok.correct_answer_title}</Text>
				</div>
				<div className="col-start-1 col-end-9">
					<div className="flex items-center mb-20">
						<DenmarkFlag width="31" className="inline mr-48"/>
						<span className="font-nove text-white-100 text-20">Danmark</span>
					</div>
					<div className="flex">
						{questionElementsArr.map((el, i) => (
							<AnswerMultipleChoiceElement
								key={`${el}-${i}`}
								correct={blok.correct_denmark_answer}
								userAnswer={answers.choice_answer_dk}
								{...el}
							/>
						))}

					</div>
					<div className="flex items-center mt-48 mb-20">
						<KenyaFlag width="31" className="inline mr-48"/>
						<span className="font-nove text-white-100 text-20">Turkana</span>
					</div>
					<div className="flex">
						{questionElementsArr.map((el, i) => (
							<AnswerMultipleChoiceElement
								key={`${el}-${i}`}
								correct={blok.correct_kenya_answer}
								userAnswer={answers.choice_answer_ke}
								{...el}
							/>
						))}
					</div>
				</div>
				<div className="col-start-9 col-end-13 px-24">
					<Face width="100%"/>
				</div>
				<div className="col-start-1 col-end-5 pt-30">
					<Text type="noveLarge" className="mb-20">{context.tr('denmarkHasAnswered')}</Text>
					<div className="flex items-center justify-between w-full text-white-100 mb-30">
						<Text type="body1" className="col-start-1 col-end-4">{context.tr('denmark')}</Text>
						<Text type="noveMedium" className="">{answers?.choice_answer_dk_all}</Text>
					</div>
					<div className="flex items-center justify-between w-full text-white-100">
						<Text type="body1" className="col-start-1 col-end-4">{context.tr('kenya')}</Text>
						<Text type="noveMedium">{answers?.choice_answer_ke_all}</Text>
					</div>
				</div>
				<ScrollDownArch className="col-start-11 col-end-13 mt-auto"/>
			</div>
		</section>
	);
};
