import React from 'react';
import { CompasNeedle } from './svgs';

export const Compas = ({ angle }) => (
	<div className="pointer-events-none compas">
		<div className="compas--needle" style={{transform: `translate(-50%, -50%) rotate(${angle}deg)`}}>
			<CompasNeedle className="w-full h-full"/>
		</div>
		<div className="font-nove text-white-100 compas--north">n</div>
		<div className="font-nove text-white-100 compas--east">ø</div>
		<div className="font-nove text-white-100 compas--south">s</div>
		<div className="font-nove text-white-100 compas--west">v</div>
	</div>
);
