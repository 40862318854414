import React, { useEffect } from 'react';
import { Ctrls2 } from './svgs';
import gsap from 'gsap';

export const NavigationHelp = ({ isVisible = true }) => {
	if(!isVisible) return null;

	useEffect(() => {
		gsap.fromTo('.mouse', {
			rotation: -15,
		},{
			rotation: 15,
			repeat: 5,
			delay: 4,
			duration: 1,
			yoyo: true,
			yoyoEase: 'power1.out',
			onComplete: () => { gsap.to('.mouse', { rotation: 0, duration: .5 }); },
		});

		gsap.to('.guidebutton', {
			backgroundColor: '#ffffffff',
			color: '#5d5d5d',
			stagger: .2,
			delay: 4,
			yoyo: true,
			onComplete: () => { gsap.to('.guidebutton', { backgroundColor: '#ffffff00', color: '#ffffff' }); },
		});
	}, []);

	return <div className="absolute flex flex-col transform pointer-events-none bottom-72 left-1/2 -translate-x-50p">
		<div className="mb-40 font-nove text-white-100 text-16">Explore the map by using</div>
		<div className="flex items-center justify-around">
			<div>
				<div className="flex justify-items-center">
					<div className="py-5 mx-auto transform translate-x-1 translate-y-1 border-2 border-b-0 guidebutton px-7 text-white-100 font-nove leading-100 font-12">w</div>
				</div>
				<div className="flex flex-wrap-reverse">
					<div className="px-8 py-5 border-2 border-r-0 guidebutton text-white-100 font-nove leading-100 font-12">a</div>
					<div className="px-8 py-5 border-2 guidebutton text-white-100 font-nove leading-100 font-12">s</div>
					<div className="px-8 py-5 border-2 border-l-0 guidebutton text-white-100 font-nove leading-100 font-12">d</div>
				</div>
			</div>
			<div className="font-bold font-nove text-white-100 text-16">and</div>
			<Ctrls2 className="mouse" />
		</div>
	</div>;
};
