import React from 'react';

export const CloseButton = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.814 117.814">
		<defs>
			<filter id="Sun" x="0" y="0" filterUnits="userSpaceOnUse">
				<feOffset dx="-8" dy="3" input="SourceAlpha"/>
				<feGaussianBlur stdDeviation="3" result="blur"/>
				<feFlood floodOpacity="0.161"/>
				<feComposite operator="in" in2="blur"/>
				<feComposite in="SourceGraphic"/>
			</filter>
		</defs>
		<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Sun)">
			<path id="Sun-2" data-name="Sun" d="M-748.839,1004.954l5.86-18.267a.864.864,0,0,1,1.687.267l-.072,19.183a.865.865,0,0,0,1.566.509l11.217-15.562a.864.864,0,0,1,1.522.776l-6,18.222a.864.864,0,0,0,1.331.968l15.478-11.335a.864.864,0,0,1,1.208,1.208l-11.334,15.478a.864.864,0,0,0,.967,1.331l18.222-6a.864.864,0,0,1,.776,1.522l-15.562,11.218a.864.864,0,0,0,.509,1.565l19.183-.072a.864.864,0,0,1,.268,1.687l-18.267,5.859a.864.864,0,0,0,0,1.646l18.267,5.86a.864.864,0,0,1-.268,1.687l-19.183-.073a.864.864,0,0,0-.509,1.565l15.562,11.218a.864.864,0,0,1-.776,1.522l-18.222-6a.864.864,0,0,0-.967,1.332l11.334,15.478a.864.864,0,0,1-1.208,1.208l-15.478-11.334a.864.864,0,0,0-1.331.967l6,18.222a.864.864,0,0,1-1.522.776L-739.8,1062.03a.865.865,0,0,0-1.566.509l.072,19.183a.864.864,0,0,1-1.687.267l-5.86-18.267a.864.864,0,0,0-1.646,0l-5.859,18.267a.864.864,0,0,1-1.687-.267l.072-19.183a.864.864,0,0,0-1.565-.509l-11.218,15.562a.864.864,0,0,1-1.522-.776l6-18.222a.864.864,0,0,0-1.332-.967l-15.477,11.334a.864.864,0,0,1-1.208-1.208l11.334-15.478a.864.864,0,0,0-.968-1.332l-18.222,6a.864.864,0,0,1-.775-1.522l15.563-11.218a.864.864,0,0,0-.509-1.565l-19.184.073a.864.864,0,0,1-.267-1.687l18.267-5.86a.864.864,0,0,0,0-1.646l-18.267-5.859a.864.864,0,0,1,.267-1.687l19.184.072a.864.864,0,0,0,.509-1.565l-15.562-11.218a.864.864,0,0,1,.775-1.522l18.222,6a.864.864,0,0,0,.968-1.331l-11.334-15.478a.864.864,0,0,1,1.208-1.208l15.477,11.335a.865.865,0,0,0,1.332-.968l-6-18.222a.864.864,0,0,1,1.522-.776l11.218,15.562a.864.864,0,0,0,1.565-.509l-.072-19.183a.864.864,0,0,1,1.687-.267l5.859,18.267A.864.864,0,0,0-748.839,1004.954Z" transform="matrix(-1, -0.03, 0.03, -1, -718.4, 1063.45)" fill="#fff"/>
		</g>
		<g id="Group_310" data-name="Group 310" transform="translate(-1121.559 -134.567)">
			<path id="Path_4879" data-name="Path 4879" d="M7854.425,3465.434l18.081,18.081" transform="translate(-6680 -3288)" fill="none" stroke="#000" strokeWidth="4"/>
			<path id="Path_4880" data-name="Path 4880" d="M7872.506,3465.434l-18.081,18.081" transform="translate(-6680 -3288)" fill="none" stroke="#000" strokeWidth="4"/>
		</g>
	</svg>
);
