import React, { useEffect, useContext, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby-link';
import clsx from 'clsx';
import gsap from 'gsap';

import { Button, Text } from './';
import { AnimationCurve2, DenmarkFlag, KenyaFlag, KenyaMapIcon } from './svgs';
import useLocalStorage from './../hooks/useLocalstorage';
import GlobalContext from '../context/global-context';
import { post } from '../services/api';
import { MultipleChoiceElement } from './multipleChoiceElement';

export const MultipleChoiceSection = ({ blok, storyId }) => {
	const [storedValue, setStoredValue] = useLocalStorage('FKN_Questions', {});
	const [storedSKTCode] = useLocalStorage('FKN_SKT', '');
	const context = useContext(GlobalContext);
	const [offset, setOffset] = useState(0);
	const animatedTextRef = useRef(null);
	const submitButtonRef = useRef(null);

	const answerOptions = Object.keys(blok)
		.filter(value => value.endsWith('_text'))
		.map((choice, index) => {
			return {
				id: blok[`choice_${index + 1}_text`],
				text: blok[`choice_${index + 1}_text`],
				image: blok[`choice_${index + 1}_image`],
			};
		});

	useEffect(() => {
		const o = { offset: offset };
		const endOffset = 700;
		const offsetAnimation = gsap.to(o, {
			offset: endOffset,
			opacity:0,
			duration: 5,
			onUpdate: () => setOffset(o.offset),
		});
		gsap.to(animatedTextRef.current, {
			opacity:0,
			duration: 5,
		});
		
		return () => {
			gsap.killTweensOf(animatedTextRef.current);
			offsetAnimation.kill();
		};
	}, []);

	const handleSubmit = async (values) => {
		if(!values.choice_answer_dk && !values.choice_answer_ke) {
			gsap.to(submitButtonRef.current, {x:'+=10', duration: .1,yoyo:true, ease:'power1.inOut', repeat:5});
			return; 
		}

		const form = {
			'question_id': storyId,
			'question_name': blok.title,
			'choice_answer_dk': values.choice_answer_dk,
			'choice_answer_ke': values.choice_answer_ke,
			'slider_answer_dk': null,
			'slider_answer_ke': null,
			'skt_kode': storedSKTCode,
		};

		const responseData = await post('svar', form );

		setStoredValue({
			...storedValue,
			[blok.answer_page_link.cached_url]: { 
				...values,
				...responseData,
				answer: {
					denmark: values.choice_answer_dk,
					kenya: values.choice_answer_ke,
				},
			},
		});

		navigate(`/${blok.answer_page_link.cached_url}`, { state: answerOptions });
	};

	return (
		<Formik 
			initialValues={{
				choice_answer_dk: '',
				choice_answer_ke: '',
			}}
			onSubmit={handleSubmit}
		>
			<Form>
				<section className="flex items-center justify-center min-h-screen question-section">
					<div className="relative grid grid-cols-12 gap-16 mx-auto max-w-grid w-92p">
						<div className="flex flex-col justify-center col-start-1 col-end-4 text-primary">
							<Text type="subheading1" className="mb-40">{blok.subheading}</Text>
							<Text type="heading1" className="mb-32">{blok.title}</Text>
							<Text type="body1">{blok.body}</Text>
							<div  className='question-section__animated-text w-66p' ref={animatedTextRef}><AnimationCurve2 text={context.tr('guessLoose')} offset={offset}/></div>
						</div>
						<div className="grid grid-cols-8 col-start-5 col-end-13 gap-16 -ml-16">
							<div className="flex items-center col-span-8">
								<DenmarkFlag width="31" className="inline mr-48"/>
								<span className="font-nove text-secondary text-20">danmark</span>
							</div>
							{answerOptions.map((option, i) => (
								<MultipleChoiceElement
									fieldName="choice_answer_dk"
									key={`${option.id}-${option.text}`}
									image={option.image}
									text={option.text}
									id={option.id}
									idx={i}
								/>
							))}
							<div className="flex items-center col-span-7 col-start-1 mt-100">
								<KenyaFlag width="31" className="inline mr-48"/>
								<span className="font-nove text-secondary text-20">Turkana</span>
							</div>
							{answerOptions.map((option, i) => (
								<MultipleChoiceElement
									fieldName="choice_answer_ke"
									key={`${option.id}-${option.text}`}
									image={option.image}
									text={option.text}
									id={option.id}
									idx={i}
								/>
							))}
						</div>
						<div className="flex justify-between col-start-1 col-end-13 pt-16">
							<KenyaMapIcon width="50"/>
							<div ref={submitButtonRef}>
								<Button 
									variant="submitButton" 
									className={clsx(
										'px-48 ml-auto question-section__submit-button',
									)}
								>
									{context.tr('next')}
								</Button>
							</div>
						</div>
					</div>
				</section>
			</Form>
		</Formik>
	);
};
