import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export const ScrollDownArch = (props) => {
	const arrowRef = useRef(null);

	useEffect(() => {
		const tl1 = gsap.timeline({ repeat:-1, repeatDelay: 1, delay:2 });

		const tlAnimation = tl1
			.to(arrowRef.current, {
				y: 0,
				duration: 0,
			})
			.to(arrowRef.current, {
				y: -20,
				duration: 1,
			})
			.to(arrowRef.current, {
				y: 0,
				duration: .5,
				ease: 'elastic.out(1, 0.5)',
			});
			
		return () => tlAnimation.kill();
	}, []);

	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" width="162.107" height="81.365" viewBox="0 0 162.107 81.365">
			<g id="Scrool_Indicator" transform="translate(-631.947 -699.224)">
				<g id="Group_90" data-name="Group 90" transform="translate(631.947 699.224)">
					<g id="Group_89" data-name="Group 89" transform="translate(0 0)">
						<path id="Path_373" data-name="Path 373" d="M1892.678-1001.035a.535.535,0,0,0-.547-.658l-.379-.035a.535.535,0,0,0-.658.547l-.105,1.146a2.665,2.665,0,0,1-.968,1.87l-.627.528a2.416,2.416,0,0,1-1.852.55l-1.481-.136a2.418,2.418,0,0,1-1.765-.842,2.586,2.586,0,0,1-.472-1.916l.475-5.172a.536.536,0,0,1,.659-.547l1.89.174a.535.535,0,0,1,.547.658l-.311,3.378a.535.535,0,0,0,.548.658l.223.02a.535.535,0,0,0,.659-.547l.087-.96a2.766,2.766,0,0,1,.665-1.68l.308-.332a1.986,1.986,0,0,1,1.731-.539l2.411.222a2.077,2.077,0,0,1,1.672,1,3.209,3.209,0,0,1,.451,2.03l-.455,4.941a.271.271,0,0,1-.333.277l-2.47-.227a.271.271,0,0,1-.277-.334Z" transform="translate(-1883.806 1075.638)" fill="#fff"/>
						<path id="Path_374" data-name="Path 374" d="M1895.592-1024.99l3,.643a.4.4,0,0,1,.351.541l-.683,3.186a2.7,2.7,0,0,1-1.212,1.766l-.107.069a2.706,2.706,0,0,1-2.115.38l-6.481-1.39a2.542,2.542,0,0,1-1.783-1.2l-.05-.087a2.775,2.775,0,0,1-.389-2c.006-.029.013-.058.019-.088l.846-3.372a.544.544,0,0,1,.723-.449l2.974.638a.534.534,0,0,1,.465.719l-.569,2.653a.535.535,0,0,0,.465.718l2.755.591a.535.535,0,0,0,.718-.465l.536-2.5A.4.4,0,0,1,1895.592-1024.99Z" transform="translate(-1885.13 1088.134)" fill="#fff"/>
						<path id="Path_375" data-name="Path 375" d="M1904.344-1046.467a.262.262,0,0,1,.123.01.254.254,0,0,1,.1.065.225.225,0,0,1,.019.22l-.83,2.417a.2.2,0,0,1-.143.141l-4.89,1.332,3.423,1.491a.429.429,0,0,1,.261.6l-.529,1.541a.4.4,0,0,1-.579.283l-10.32-3.543q-.565-.194-.414-.7l1.35-4.554.049-.141a2.713,2.713,0,0,1,1.281-1.567,2.372,2.372,0,0,1,2-.159l2.05.7a2.627,2.627,0,0,1,1.6,1.418l.535,1.156Zm-8.411.494a.678.678,0,0,0-.873.333l-.8,1.291a.474.474,0,0,0-.034.075q-.059.169.149.256l2.343.955.028.01a.2.2,0,0,0,.29-.13.224.224,0,0,0,0-.128l-.46-1.88A1.007,1.007,0,0,0,1895.934-1045.974Z" transform="translate(-1887.749 1101.134)" fill="#fff"/>
						<path id="Path_376" data-name="Path 376" d="M1911.884-1071.316a4.181,4.181,0,0,1,1.64,1.509l.342.533a1.905,1.905,0,0,1,.093,2.016l-1.873,3.7a2.67,2.67,0,0,1-1.641,1.339l-.1.033a2.8,2.8,0,0,1-2.168-.218l-5.681-2.775-.033-.017a2.339,2.339,0,0,1-1.238-1.624l-.017-.067a3.061,3.061,0,0,1,.292-2.164l1.583-3.127a2.67,2.67,0,0,1,1.628-1.4l.549-.174a2.732,2.732,0,0,1,2.138.161Zm-1.345,2.971-3.46-1.752a.558.558,0,0,0-.813.217l-.812,1.306a.543.543,0,0,0-.047.076.513.513,0,0,0,.267.747l3.466,1.989.074.037a.578.578,0,0,0,.886-.263.7.7,0,0,0,.041-.1l.643-1.568A.48.48,0,0,0,1910.539-1068.345Z" transform="translate(-1893.96 1115.62)" fill="#fff"/>
						<path id="Path_377" data-name="Path 377" d="M1909.906-1088.54a.273.273,0,0,1,.413-.092l7.918,4.818,1.42-2.116a.535.535,0,0,1,.84-.166l2.755,1.848a.535.535,0,0,1,.166.84l-2.719,4.052a.516.516,0,0,1-.807.17l-.037-.025-10.68-7.715a.269.269,0,0,1-.072-.417Z" transform="translate(-1898.598 1124.296)" fill="#fff"/>
						<path id="Path_378" data-name="Path 378" d="M1920.981-1103.939a.273.273,0,0,1,.419-.052l7.428,5.543,1.614-1.973a.535.535,0,0,1,.852-.086l2.568,2.1a.535.535,0,0,1,.085.852l-3.089,3.778a.515.515,0,0,1-.819.093l-.035-.028-9.9-8.689a.268.268,0,0,1-.032-.421Z" transform="translate(-1905.017 1133.312)" fill="#fff"/>
						<path id="Path_379" data-name="Path 379" d="M1951.665-1127.4l2.4,3.511a.5.5,0,0,1-.1.74l-1.127.959a.466.466,0,0,1-.743-.055l0-.005-7.088-8.511a.473.473,0,0,1,.06-.748l1.3-1.1a.305.305,0,0,1,.265-.069l4.4.841-2.521-4.772a.528.528,0,0,1,.137-.715l1-.848q.393-.334.688.013a.552.552,0,0,1,.052.073l7.526,10.719a.487.487,0,0,1-.1.729l-1.042.886a.287.287,0,0,1-.3.056Z" transform="translate(-1919.599 1153.41)" fill="#fff"/>
						<path id="Path_380" data-name="Path 380" d="M1976.108-1137.77l.828,1.281a.473.473,0,0,1-.15.735l-4.751,3.139a.737.737,0,0,1-1.149-.235l-5.768-8.621a.66.66,0,0,1,.218-1.039l4.727-3.149a.6.6,0,0,1,.938.186l0,.006,1.289,1.979a.6.6,0,0,1-.193.942l-2.83,1.87a.268.268,0,0,0-.081.42l.54.816a.274.274,0,0,0,.394.108l2.943-1.47a.263.263,0,0,1,.381.089l0,.006.572.894a.472.472,0,0,1-.15.735l-2.378,1.553a.47.47,0,0,0-.15.735l.528.8a.271.271,0,0,0,.424.087l3.076-2.015a.466.466,0,0,1,.731.144Z" transform="translate(-1931.386 1157.789)" fill="#fff"/>
						<path id="Path_381" data-name="Path 381" d="M1994.531-1149.281a2.675,2.675,0,0,1,.142,2.132l-.377,1.106a2.707,2.707,0,0,1-1.4,1.614l-3.768,1.873a.6.6,0,0,1-.912-.306l-4.758-9.57a.568.568,0,0,1,.255-.828l3.742-2.21q.09-.053.191-.1a2.39,2.39,0,0,1,1.919-.119l1.019.353a2.723,2.723,0,0,1,1.624,1.387Zm-3,1.848-2.106-4.236a.668.668,0,0,0-.774-.383l-1.03.162a.33.33,0,0,0-.1.033.265.265,0,0,0-.125.4l.007.013,2.742,5.247q.146.278.381.161a.716.716,0,0,0,.083-.05l.767-.573A.56.56,0,0,0,1991.535-1147.433Z" transform="translate(-1942.186 1163.668)" fill="#fff"/>
						<path id="Path_382" data-name="Path 382" d="M2026.3-1166.783a.535.535,0,0,1-.42.746l-2.34.653a.271.271,0,0,0-.213.378l.51,1.828a.271.271,0,0,0,.378.213l1.951-.544a.4.4,0,0,1,.562.316l.385,1.382a.411.411,0,0,1-.285.561l-2.243.8a.28.28,0,0,0-.21.33l.51,4.249a.508.508,0,0,1-.4.616l-1.541.43a.47.47,0,0,1-.654-.368l-3.274-11.734a.667.667,0,0,1,.525-.93l5.413-1.51a.535.535,0,0,1,.745.42Z" transform="translate(-1963.061 1171.619)" fill="#fff"/>
						<path id="Path_383" data-name="Path 383" d="M2051.067-1164.963a4.174,4.174,0,0,1-.3,2.209l-.24.586a1.905,1.905,0,0,1-1.6,1.233l-4.106.589a2.675,2.675,0,0,1-2.038-.576l-.084-.063a2.8,2.8,0,0,1-1.065-1.9l-.986-6.245,0-.037a2.337,2.337,0,0,1,.62-1.946l.045-.052a3.06,3.06,0,0,1,1.939-1l3.47-.5a2.668,2.668,0,0,1,2.077.533l.458.35a2.731,2.731,0,0,1,1.095,1.844Zm-3.205.6-.551-3.839a.558.558,0,0,0-.644-.542l-1.535.084a.587.587,0,0,0-.09.005q-.511.073-.459.647l.359,3.98c0,.03.009.057.012.081q.1.666.724.575a.711.711,0,0,0,.1-.022l1.652-.373A.481.481,0,0,0,2047.862-1164.36Z" transform="translate(-1975.756 1173.555)" fill="#fff"/>
						<path id="Path_384" data-name="Path 384" d="M2077.176-1162.833a.274.274,0,0,1,.032.119.249.249,0,0,1-.028.113.225.225,0,0,1-.2.093l-2.556.042a.2.2,0,0,1-.181-.087l-2.915-4.146-.239,3.726a.429.429,0,0,1-.472.448l-1.629.026a.4.4,0,0,1-.463-.448l-.177-10.909q-.01-.6.521-.629l4.742-.279.149,0a2.715,2.715,0,0,1,1.91.671,2.38,2.38,0,0,1,.83,1.826l.035,2.167a2.629,2.629,0,0,1-.79,1.986l-.905.9Zm-3.325-7.742a.677.677,0,0,0-.609-.708l-1.485-.312a.541.541,0,0,0-.082-.006c-.119,0-.183.078-.19.227l-.1,2.528v.03q0,.231.221.228a.228.228,0,0,0,.119-.047l1.611-1.073A1,1,0,0,0,2073.852-1170.575Z" transform="translate(-1992.012 1174.767)" fill="#fff"/>
						<path id="Path_385" data-name="Path 385" d="M2110.87-1155.848a.3.3,0,0,1,0,.091.265.265,0,0,1-.358.225l-2.38-.481a.666.666,0,0,1-.575-.551l-.605-3.08-2.526-.929-.9,1.342a.529.529,0,0,1-.572.266l-2.139-.432a.261.261,0,0,1-.236-.345.478.478,0,0,1,.039-.114l6.35-10.04a.691.691,0,0,1,.714-.328l1.681.172a1.173,1.173,0,0,1,.126.018.659.659,0,0,1,.582.666Zm-3.776-6.29-.077-2.486a.307.307,0,0,0-.281-.308.229.229,0,0,0-.271.136l-1.055,2.051a.578.578,0,0,0-.063.163c-.03.146.041.252.21.317l1.146.438a.141.141,0,0,0,.043.016.258.258,0,0,0,.343-.228A.4.4,0,0,0,2107.094-1162.138Z" transform="translate(-2010.901 1172.107)" fill="#fff"/>
						<path id="Path_386" data-name="Path 386" d="M2131.527-1154.23a.482.482,0,0,1-.673.319l-1.39-.462a.476.476,0,0,1-.343-.674l2.013-6.45a.275.275,0,0,0-.194-.388l-1.27-.422a.535.535,0,0,1-.383-.766l.975-2.928a.552.552,0,0,1,.756-.4l6.774,1.916.028.01a.264.264,0,0,1,.18.383l-1.279,3.843a.268.268,0,0,1-.381.2l-.007,0-1.945-.663-.007,0a.27.27,0,0,0-.376.182Z" transform="translate(-2027.623 1169.883)" fill="#fff"/>
						<path id="Path_387" data-name="Path 387" d="M2161.9-1156.653a.272.272,0,0,1,.131.4l-4.046,8.338,2.241,1.213a.535.535,0,0,1,.243.821l-1.579,2.918a.535.535,0,0,1-.82.244l-4.292-2.323a.515.515,0,0,1-.245-.787c.007-.013.014-.027.022-.039l6.669-11.364a.268.268,0,0,1,.408-.11Z" transform="translate(-2041.906 1164.579)" fill="#fff"/>
						<path id="Path_388" data-name="Path 388" d="M2177.926-1126.749q.427.315.157.682l-1.046,1.42a.6.6,0,0,1-.951.144l-3.724-2.744a.436.436,0,0,1-.2-.53l.263-.734a.591.591,0,0,0-.154-.634l-1.5-1.6a.532.532,0,0,0-.08-.068.344.344,0,0,0-.444,0l-1.027.738a.6.6,0,0,1-.767-.055l-1.516-1.117q-.422-.31-.173-.647a1.2,1.2,0,0,1,.3-.27l9.21-6.2a.879.879,0,0,1,1.1-.016l5.8,4.274a.6.6,0,0,1,.144.951l-1.348,1.829q-.257.349-.69.03l-2.2-1.622a.469.469,0,0,0-.742.112l-.465.632q-.24.325.128.6l1.173.865a.473.473,0,0,1,.108.748l-.488.661q-.275.374-.608.22l-1.217-.553a.437.437,0,0,0-.575.188l-.523.71a.337.337,0,0,0,.081.533Zm-3.515-4.539,2.239-3.038q.3-.409-.042-.662a.5.5,0,0,0-.638.059l-3.119,2.242a.662.662,0,0,0-.154.146q-.177.241.112.491l.912.792a.56.56,0,0,0,.079.068A.387.387,0,0,0,2174.411-1131.288Z" transform="translate(-2049.659 1153.542)" fill="#fff"/>
						<path id="Path_389" data-name="Path 389" d="M2198.173-1108.248a.266.266,0,0,1-.063.1.256.256,0,0,1-.1.058.223.223,0,0,1-.205-.08l-1.8-1.815a.2.2,0,0,1-.063-.19l.974-4.973-2.854,2.407a.429.429,0,0,1-.65-.029l-1.147-1.157a.4.4,0,0,1,0-.645l7.749-7.68a.526.526,0,0,1,.815-.06l3.484,3.228.1.107a2.71,2.71,0,0,1,.837,1.843,2.372,2.372,0,0,1-.743,1.862l-1.539,1.525a2.629,2.629,0,0,1-1.979.8l-1.273-.032Zm3.285-7.759a.678.678,0,0,0,.089-.929l-.8-1.288a.452.452,0,0,0-.053-.064q-.126-.127-.3.02l-1.893,1.677-.021.021a.2.2,0,0,0-.012.318.226.226,0,0,0,.116.053l1.89.421A1.007,1.007,0,0,0,2201.458-1116.007Z" transform="translate(-2064.576 1144.106)" fill="#fff"/>
						<path id="Path_390" data-name="Path 390" d="M2213.02-1092.655l-1.211.926a.473.473,0,0,1-.745-.093l-3.5-4.492a.737.737,0,0,1,.145-1.165l8.146-6.421a.66.66,0,0,1,1.054.136l3.508,4.467a.6.6,0,0,1-.112.95l-.006,0-1.872,1.44a.605.605,0,0,1-.955-.119l-2.085-2.677a.267.267,0,0,0-.425-.048l-.772.6a.273.273,0,0,0-.077.4l1.694,2.82a.262.262,0,0,1-.058.386l-.006.005-.846.64a.473.473,0,0,1-.744-.093l-1.733-2.25a.469.469,0,0,0-.744-.093l-.755.588a.272.272,0,0,0-.054.43l2.248,2.909a.466.466,0,0,1-.086.74Z" transform="translate(-2073.512 1133.333)" fill="#fff"/>
						<path id="Path_391" data-name="Path 391" d="M2229.978-1065.549l-3.288.253a.611.611,0,0,1-.64-.355l-.054-.107a.563.563,0,0,1,.141-.733l2.667-2.41-3.287,1.132a.274.274,0,0,1-.378-.135l-.69-1.368a.267.267,0,0,1,.129-.408l.02-.01,9.731-4.519a.541.541,0,0,1,.8.274l.855,1.695a.67.67,0,0,1-.154.839l-2.583,2.577,3.638-.422a.688.688,0,0,1,.757.4l.882,1.748a.516.516,0,0,1-.237.79l-.034.017-10.155,4.725a.266.266,0,0,1-.392-.146l-.593-1.174a.28.28,0,0,1,.076-.381Z" transform="translate(-2083.514 1115.851)" fill="#fff"/>
						<path id="Path_392" data-name="Path 392" d="M2239.309-1040.582l-1.443.494a.473.473,0,0,1-.677-.324l-1.9-5.369a.737.737,0,0,1,.506-1.059l9.76-3.511a.66.66,0,0,1,.956.463l1.913,5.348a.6.6,0,0,1-.407.865l-.007,0-2.232.773a.605.605,0,0,1-.868-.415l-1.13-3.2a.268.268,0,0,0-.389-.18l-.923.326a.273.273,0,0,0-.2.355l.715,3.212a.263.263,0,0,1-.178.348l-.007,0-1.006.34a.473.473,0,0,1-.677-.323l-.932-2.683a.469.469,0,0,0-.677-.323l-.9.318a.271.271,0,0,0-.187.391l1.211,3.472a.466.466,0,0,1-.316.675Z" transform="translate(-2089.857 1101.856)" fill="#fff"/>
						<path id="Path_393" data-name="Path 393" d="M2243.3-1018.842a.27.27,0,0,1-.111.055.25.25,0,0,1-.115-.005.224.224,0,0,1-.131-.177l-.555-2.5a.2.2,0,0,1,.049-.194l3.474-3.689-3.7.516a.429.429,0,0,1-.534-.371l-.354-1.59a.4.4,0,0,1,.346-.544l10.651-2.369q.583-.13.721.383l1.227,4.589.033.146a2.713,2.713,0,0,1-.274,2.005,2.375,2.375,0,0,1-1.621,1.18l-2.116.47a2.628,2.628,0,0,1-2.1-.374l-1.06-.706Zm6.914-4.815a.678.678,0,0,0,.571-.739l.007-1.518a.5.5,0,0,0-.01-.082c-.026-.117-.113-.163-.261-.141l-2.5.41-.029.007c-.151.033-.211.121-.18.262a.225.225,0,0,0,.07.107l1.375,1.363A1,1,0,0,0,2250.217-1023.657Z" transform="translate(-2093.427 1089.726)" fill="#fff"/>
						<path id="Path_394" data-name="Path 394" d="M2248.208-997.276l-1.519.135a.473.473,0,0,1-.58-.475l-.562-5.667a.737.737,0,0,1,.743-.907l10.317-1.083a.66.66,0,0,1,.818.677l.583,5.65a.6.6,0,0,1-.6.743h-.008l-2.351.218a.6.6,0,0,1-.744-.61l-.335-3.376a.267.267,0,0,0-.334-.267l-.975.1a.274.274,0,0,0-.278.3l-.072,3.289a.262.262,0,0,1-.256.3h-.007l-1.058.09a.473.473,0,0,1-.58-.476l-.266-2.827a.469.469,0,0,0-.581-.476l-.952.094a.271.271,0,0,0-.274.335l.349,3.66a.466.466,0,0,1-.469.58Z" transform="translate(-2095.908 1075.389)" fill="#fff"/>
					</g>
				</g>
			</g>
			<g ref={arrowRef}>
				<path id="Path_4825" data-name="Path 4825" d="M11408.945-3212.931l13.014,13.013,13.012-13.013" transform="translate(-11340 3279.162)" fill="none" stroke="#fff" strokeWidth="3"/>
				<line id="Line_543" data-name="Line 543" y1="36" transform="translate(81.958 43.244)" fill="none" stroke="#fff" strokeWidth="3"/>
			</g>
		</svg>
	);
};
