import React, { useMemo } from 'react';
import clsx from 'clsx';
import { themes } from '../components/canvas/markers';

const getDots = (themes, currentTheme) => {
	const dots = [];
	for (const theme of themes) {
		for (const marker of theme.questions) {
			if(currentTheme === null || currentTheme === theme.theme) {
				dots.push(<Dot key={`${theme.theme}-${marker.minimapPosition[0]}`} theme={theme.theme}position={marker.minimapPosition}/>);
			}
		}
	}
	return dots;
};

const Dot = ({ theme, position }) => {
	return <div className={clsx('minimap__marker', `minimap__${theme}`)} style={{top: position[0], left: position[1]}}></div>;
};

const MiniMapMarker = ({ cameraPosition }) => (
	<div className="minimap--dot"
		style={{transform: `translate(${120 * cameraPosition[1] / 200}px,
		${(- 120 * cameraPosition[0] / 200)}px)`}}
	>
		<div className="minimap--dot__inner"></div>
	</div>
);

export const MiniMap = ({ currentTheme, cameraPosition }) => {
	const dots = useMemo(() => getDots(themes, currentTheme), [currentTheme]);
	return (
		<div className="minimap">
			<div className="minimap__map"></div>
			<MiniMapMarker cameraPosition={cameraPosition}/>
			{dots}
		</div>
	);
};
