import React from 'react';

export const KenyaMapIcon = (props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.848 36.704">
		<g transform="translate(-496.248 -198.573)">
			<path data-name="Path 628" d="M519.193,222.971a.276.276,0,0,1-.313-.313V211.372a.552.552,0,0,1,.6-.626l2.534-.061h.031q.587,0,.588.549v.061l-.237,4.083,3.655-5.64a.28.28,0,0,1,.252-.13h2.236q.328,0,.328.3a.3.3,0,0,1-.054.176l-3.564,6.3,2.656,6.235a1.07,1.07,0,0,1,.107.412q0,.389-.549.389h-2.312a.713.713,0,0,1-.694-.389l-2.595-5.143-.389,4.8a.28.28,0,0,1-.313.29Z" transform="translate(-19.54 -9.528)"/>
			<path data-name="Path 629" d="M602.477,208.692l-.015,1.5a.465.465,0,0,1-.521.521H596.35a.724.724,0,0,1-.815-.815l-.059-10.185a.648.648,0,0,1,.741-.734l5.577-.022a.587.587,0,0,1,.668.66v.007l-.015,2.319a.594.594,0,0,1-.668.668h-3.331a.263.263,0,0,0-.293.3v.961a.268.268,0,0,0,.264.3l3.207.389a.258.258,0,0,1,.264.279v.007l-.015,1.042a.464.464,0,0,1-.521.521l-2.788-.015a.461.461,0,0,0-.521.521v.939a.266.266,0,0,0,.3.3l3.61.015a.457.457,0,0,1,.521.514Z" transform="translate(-85.672 -0.33)"/>
			<path data-name="Path 630" d="M656.334,207.921l-.44,4.153a.489.489,0,0,1-.543.492H653.9a.457.457,0,0,1-.521-.514v-.007l.117-10.875a.464.464,0,0,1,.521-.521h1.673a.3.3,0,0,1,.242.117l2.752,3.427,1.152-5.173a.52.52,0,0,1,.558-.448h1.284q.506,0,.506.448a.55.55,0,0,1-.007.088l-1.2,12.8a.478.478,0,0,1-.536.484H659.1a.282.282,0,0,1-.257-.147Z" transform="translate(-135.662 0)"/>
			<path data-name="Path 631" d="M778.244,230.1a.275.275,0,0,1,.015.088q0,.286-.3.286h-2.385a.654.654,0,0,1-.661-.418l-1.181-2.847-2.612-.4-.609,1.468a.52.52,0,0,1-.5.367h-2.143a.256.256,0,0,1-.293-.286.48.48,0,0,1,.015-.117l4.161-10.9a.678.678,0,0,1,.624-.455l1.651-.162a1.164,1.164,0,0,1,.125-.007.648.648,0,0,1,.69.528Zm-4.857-5.32-.558-2.378a.3.3,0,0,0-.33-.242.225.225,0,0,0-.235.184l-.616,2.179a.585.585,0,0,0-.029.169q0,.22.264.264l1.189.2a.137.137,0,0,0,.044.007.253.253,0,0,0,.286-.286A.353.353,0,0,0,773.387,224.783Z" transform="translate(-234.259 -15.667)"/>
			<path data-name="Path 632" d="M567.413,350.961a3.716,3.716,0,0,1-.538,1.907l-.286.485a1.7,1.7,0,0,1-1.561.884H561.34a2.377,2.377,0,0,1-1.721-.764l-.066-.066a2.486,2.486,0,0,1-.7-1.807l-.08-5.621v-.033a2.081,2.081,0,0,1,.791-1.635l.047-.04a2.72,2.72,0,0,1,1.834-.638h3.116a2.372,2.372,0,0,1,1.761.731l.359.365a2.429,2.429,0,0,1,.731,1.761Zm-2.9.126v-3.448a.5.5,0,0,0-.5-.558l-1.362-.12a.493.493,0,0,0-.08-.007q-.458,0-.485.512l-.186,3.548v.073q0,.6.565.6a.657.657,0,0,0,.093-.007l1.5-.12A.428.428,0,0,0,564.516,351.087Z" transform="translate(-53.985 -125.241)"/>
			<path data-name="Path 633" d="M689.561,362.626a.474.474,0,0,1-.528.506h-1.438a.468.468,0,0,1-.528-.521l-.125-6.633a.27.27,0,0,0-.3-.3h-1.314a.525.525,0,0,1-.594-.594v-3.031a.542.542,0,0,1,.58-.609l6.9-.316h.029q.286,0,.286.3v3.977a.263.263,0,0,1-.294.3h-.007l-2.018-.015h-.007a.264.264,0,0,0-.294.286Z" transform="translate(-162.735 -131.713)"/>
			<path data-name="Path 634" d="M754.951,357.692l-.015,1.5a.464.464,0,0,1-.521.521h-5.592a.724.724,0,0,1-.814-.814l-.059-10.185a.648.648,0,0,1,.741-.734l5.577-.022a.587.587,0,0,1,.668.66v.007l-.015,2.319a.594.594,0,0,1-.668.668h-3.331a.263.263,0,0,0-.294.3v.961a.268.268,0,0,0,.264.3l3.207.389a.258.258,0,0,1,.264.279v.007l-.015,1.042a.465.465,0,0,1-.521.521l-2.788-.015a.46.46,0,0,0-.521.521v.939a.266.266,0,0,0,.3.3l3.61.015a.457.457,0,0,1,.521.514Z" transform="translate(-217.316 -128.974)"/>
			<path data-name="Path 635" d="M808.853,339.812a.474.474,0,0,1-.528.506h-1.438a.468.468,0,0,1-.528-.521l-.125-8.819a.27.27,0,0,0-.3-.3h-1.314a.525.525,0,0,1-.594-.594v-3.031a.542.542,0,0,1,.58-.609l6.9-.316h.029q.286,0,.286.3v3.977a.263.263,0,0,1-.294.3h-.007l-2.018-.015H809.5a.264.264,0,0,0-.293.286Z" transform="translate(-265.729 -110.129)"/>
			<path data-name="Path 636" d="M626.6,202.2h-1.959a.274.274,0,0,0-.264.161l-1.644,3.17a.288.288,0,0,1-.272.169.273.273,0,0,1-.264-.183l-.917-2a.66.66,0,0,0-.66-.382H618.6q-.646,0-.646.455a1.208,1.208,0,0,0,.11.448l2.524,6.083-.438,3.768h0l-.637,3.12a.692.692,0,0,1-.738.551l-11.931-1.838a.167.167,0,0,1-.144-.182l.079-.785a.194.194,0,0,0-.3-.178l-4.391,2.5a.389.389,0,0,0-.076.62l3.462,3.312a.194.194,0,0,0,.335-.091l.168-.838a.166.166,0,0,1,.179-.133l15.786,1.8a.726.726,0,0,0,.818-.635l.784-6.658a20.171,20.171,0,0,1,.844-4.909c.838-2.963,2.51-6.942,2.51-6.942a.435.435,0,0,0,.015-.1A.266.266,0,0,0,626.6,202.2Z" transform="translate(-91.202 -3.131)"/>
			<path data-name="Path 637" d="M504.323,340.213a.693.693,0,0,0,.738.552l11.66-1.548a.167.167,0,0,0,.144-.181l-.077-.785a.194.194,0,0,1,.3-.178l4.388,2.51a.389.389,0,0,1,.075.62l-3.467,3.307a.194.194,0,0,1-.335-.092l-.166-.838a.167.167,0,0,0-.179-.133l-15.515,1.5a.726.726,0,0,1-.817-.636l-1.1-5.961-.859-3.971L498.743,339a.269.269,0,0,1-.3.279h-1.893a.266.266,0,0,1-.3-.3V328.124a.53.53,0,0,1,.58-.6l2.436-.059h.029q.565,0,.565.528v.059l-.227,3.926,4.061-6.242a.269.269,0,0,1,.242-.125h2.15q.315,0,.315.286a.287.287,0,0,1-.051.169l-3.973,6.873Z" transform="translate(0 -109.681)"/>
			<path data-name="Path 638" d="M650,364" transform="translate(-132.747 -142.827)"/>
			<path data-name="Path 639" d="M635.961,351.923a.226.226,0,0,1,.026.1.215.215,0,0,1-.026.1.192.192,0,0,1-.173.077H633.6a.173.173,0,0,1-.154-.077L631,348.527l-.256,3.191a.368.368,0,0,1-.41.378h-1.4a.345.345,0,0,1-.391-.391V342.35q0-.513.455-.532l4.069-.173h.128a2.325,2.325,0,0,1,1.628.6,2.035,2.035,0,0,1,.685,1.576v1.858a2.253,2.253,0,0,1-.7,1.692l-.788.756Zm-2.742-6.683a.581.581,0,0,0-.513-.615l-1.269-.288a.455.455,0,0,0-.071-.006q-.154,0-.167.192l-.122,2.166v.026q0,.2.186.2a.191.191,0,0,0,.1-.038l1.4-.9A.862.862,0,0,0,633.219,345.24Z" transform="translate(-114.226 -123.526)"/>
		</g>
	</svg>
);
