import React from 'react';

export const PlaySmall = ({ className, ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" className={className} {...rest} width="13" height="13" viewBox="0 0 13 13">
		<g fill="#fff">
			<path d="M 6.499760150909424 12.50030040740967 C 3.191480159759521 12.50030040740967 0.5000001788139343 9.808469772338867 0.5000001788139343 6.499760150909424 C 0.5000001788139343 3.191480159759521 3.191480159759521 0.5000001788139343 6.499760150909424 0.5000001788139343 C 9.808469772338867 0.5000001788139343 12.50030040740967 3.191480159759521 12.50030040740967 6.499760150909424 C 12.50030040740967 9.808469772338867 9.808469772338867 12.50030040740967 6.499760150909424 12.50030040740967 Z M 4.70028018951416 3.431650161743164 L 4.70028018951416 4.333690166473389 L 4.70028018951416 8.666609764099121 L 4.70028018951416 9.568819999694824 L 5.465310096740723 9.090590476989746 L 8.931640625 6.923740386962891 L 9.610010147094727 6.499680042266846 L 8.931580543518066 6.075740337371826 L 5.465250015258789 3.909670114517212 L 4.70028018951416 3.431650161743164 Z" stroke="none"/>
			<path d="M 6.499760150909424 12.00030040740967 C 9.532770156860352 12.00030040740967 12.00030040740967 9.532770156860352 12.00030040740967 6.499760150909424 C 12.00030040740967 3.467180252075195 9.532770156860352 1.000000238418579 6.499760150909424 1.000000238418579 C 3.467180252075195 1.000000238418579 1.000000238418579 3.467180252075195 1.000000238418579 6.499760150909424 C 1.000000238418579 9.532770156860352 3.467180252075195 12.00030040740967 6.499760150909424 12.00030040740967 M 4.20028018951416 2.529610157012939 L 10.55340003967285 6.499610424041748 L 4.20028018951416 10.47103023529053 L 4.20028018951416 2.529610157012939 M 6.499760150909424 13.00030040740967 C 2.915780305862427 13.00030040740967 2.037048290048915e-07 10.0841703414917 2.037048290048915e-07 6.499760150909424 C 2.037048290048915e-07 2.915780305862427 2.915780305862427 2.037048290048915e-07 6.499760150909424 2.037048290048915e-07 C 10.0841703414917 2.037048290048915e-07 13.00030040740967 2.915780305862427 13.00030040740967 6.499760150909424 C 13.00030040740967 10.0841703414917 10.0841703414917 13.00030040740967 6.499760150909424 13.00030040740967 Z M 5.20028018951416 4.333690166473389 L 5.20028018951416 8.666609764099121 L 8.666609764099121 6.499760150909424 L 5.20028018951416 4.333690166473389 Z" stroke="none" fill="#fff"/>
		</g>
	</svg>
);
