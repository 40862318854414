import React from 'react';
import { Text } from '../typography';
import { Field, useField } from 'formik';
import nf from '../../utils/numberFormatter';
import clsx from 'clsx';

export const RangeSlider = ({ step = 1, min = 0, max = 100, name='', units, preText }) => {
	const [field, , ] = useField(name);
	return (<>
		<div className="range-slider__input-wrapper">
			<Text type="rangeNumber" className="range-slider__range-number">{min}</Text>
			<input
				type="range"
				name={name}
				step={step}
				min={min}
				max={max}
				{...field}
				className="w-full h-3 mx-8 rounded-lg appearance-none range bg-primary"
			/>
			<Text type="rangeNumber" className="range-slider__range-number">{`${nf(max)} ${units}`}</Text>
		</div>
		<Text type="noveMedium" className="block max-w-xs mx-auto text-center px-15">{`${preText} ${nf(field.value)} ${units}`}</Text>
	</>);
};

export const Input = ({ className, name, ...rest }) => {
	const [field, , ] = useField(name);
  
	return (
		<div className={clsx(className, 'input relative')}>
			<Field
				name={name}
				value={field.value}
				className="italic input-field bg-primary text-17"
				{...rest}
			/>
			<div className="cursor" />
		</div>
	); 
};