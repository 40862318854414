import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Ticker from 'react-ticker';
import { isBrowser } from '../helpers/isBrowser';

const LIVESCHEDULE = [
	{
		startTime: [2021, 10, 8, 9, 0, 0, 0],
		endTime: [2021, 10, 8, 10, 45, 0, 0],
	},
	{
		startTime: [2021, 10, 25, 8, 0, 0, 0],
		endTime: [2021, 10, 29, 10, 45, 0, 0],
	},
	{
		startTime: [2021, 11, 9, 8, 0, 0 , 0],
		endTime: [2021, 11, 13, 10, 45, 0, 0],
	},
];

export const NewsTicker = () => {
	const [isMoving, setMoving] = useState(true);
	const [isLive, setLive] = useState(false);

	useEffect(() => {
		if(isBrowser()) {
			let isLive = false;
			const now = new Date();
			isLive = LIVESCHEDULE.some(({ startTime, endTime }) => {
				const start = new Date(...startTime);
				const end = new Date(...endTime);
				return now > start && now < end;
			});
			setLive(isLive);
		}
	}, []);

	const handleMouseEnter = () => setMoving(false);
	const handleMouseLeave = () => setMoving(true);

	return (<>
		{isLive ? <div className="news-ticker" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<Ticker move={isMoving}>
				{({ index }) => (
					<Link key={`newsticker-${index}`} to="/liveshow" className="inline-block px-8">
						<span className="ml-16 text-black font-nove text-15">HUSK AT SE LIVESHOW MED ANNA LIN OG GÆSTER PÅ MANDAG d. KL. 10.00 -  HER PÅ SIDEN. </span>
					</Link>
				)}
			</Ticker>
		</div>
			:
			null
		}
	</>);
};

/* {repeat.map((_, i) => 
		<Link key={`${i}-newsticker`} to="/liveshow" className="inline-block px-8">
			<span className="ml-16 text-black font-nove text-15">HUSK AT SE LIVESHOW MED ANNA LIN OG GÆSTER PÅ MANDAG KL. 10.00 HER PÅ SIDEN.</span>
		</Link>,
	)}
	</div>
	<div className="news-ticker__content">
	{repeat.map((_, i) => 
		<Link key={`${i}-newsticker-clone`} to="/liveshow" className="inline-block px-8">
			<span className="ml-16 text-black font-nove text-15">HUSK AT SE LIVESHOW MED ANNA LIN OG GÆSTER PÅ MANDAG KL. 10.00 HER PÅ SIDEN.</span>
		</Link>,
	)}
*/

// export const NewsTicker = () => {
// 	return <NewsTickerElement />;
// const [status, setStatus] = useState(false);

// const getTickerStatus = () => {
// 	const currentTime = new Date();

// 	let nextShow = null;
// 	let nextShowIndex = null;

// 	const isLive = liveShowTimes.some((liveshow, idx) => {
// 		const eventTime = new Date(...liveshow.time);
// 		const diffMinutes = Math.ceil((currentTime - eventTime) / (1000 * 60));

// 		if(diffMinutes < 0 && (nextShowIndex === null || eventTime < nextShow )) {
// 			nextShow = eventTime;
// 			nextShowIndex = idx;
// 		}

// 		return diffMinutes > -30 && diffMinutes <= 30;
// 	});

// 	setStatus(isLive);

// if(isLive) {
// 	// return live text
// 	console.log(isLive)
// }

// let liveIn = 0;
// const isAlmostLive = liveShowTimes.some(liveshow => {
// 	const eventTime = new Date(...liveshow.time);
// 	const diffMinutes = Math.ceil((currentTime - eventTime) / (1000 * 60));
// 	liveIn = diffMinutes;
// 	return diffMinutes > -20 && diffMinutes <= 0;
// });

// if (isAlmostLive) {
// 	console.log( 'show live in ' + Math.abs(liveIn) + ' min');
// }

// for(let liveshow in liveShowTimes) {
// 	const eventTime = new Date(...liveShowTimes[liveshow].time);
// 	const diffMinutes = Math.ceil((currentTime - eventTime) / (1000 * 60));

// 	if(diffMinutes < 0 && (nextShowIndex === null || eventTime < nextShow )) {
// 		nextShow = eventTime;
// 		nextShowIndex = liveshow;
// 	}

// if (diffMinutes > -20 && diffMinutes < 0) {
// 	return 'live soon';
// } else if ( diffMinutes > -20 && diffMinutes <= 30) {
// 	return 'live'
// }
// return 'not live'
// }
// };

// useEffect(() => {
// 	getTickerStatus();
// }, []);

// if(!status) return null;

// return <NewsTickerElement />;
// };
