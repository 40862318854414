const BASEURL = 'https://gotyuback.yu.agency/kenyakortet/api';

const callApi = async (url = '', options = {}, data = {}) => {
	try {
		let response;
		if (options?.method === 'POST') {
			response = await fetch(`${BASEURL}/${url}/`, {
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(data),
			});
		} else {
			response = await fetch(`${BASEURL}/${url}`, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
		}

		if (!response.ok) {
			throw new Error(response.statusText + ', ' + response.url);
		}

		const responseData = await response.json();
		return responseData;

	} catch(e) {
		console.warn(e);
	}
};


export const post = (url, args) => {
	return callApi(
		url,
		{
			method: 'POST',
		},
		{
			...args,
		},
	);
};

export const get = async url => {
	return callApi(url);
};
