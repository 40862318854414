import React from 'react';

export const AnimationCurve2 = ({ text, offset, fontSize = 48 }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 855 185.79" className="absolute overflow-visible pointer-events-none bottom-0 w-full">
		<path id="TextAnimation_path2" fill="#ffffff00" stroke="#ffffff00" d="M8.29,157.38c0,0,155.26,88.85,325.27-72.48c16.39-15.55,34.26-29.5,53.65-41.11
	c100.3-60.05,210.37-65.97,459.5,70.65"/>
		<text fontSize={`${fontSize}px`} fill="#F8BA7B">
			<textPath xlinkHref="#TextAnimation_path2" startOffset={`${offset}px`}>
				{ text }
			</textPath>
		</text>
	</svg>
);
