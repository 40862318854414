import React from 'react';
import { RangeSlider } from '../components/form/input';
import clsx from 'clsx';
import LazyImage from './lazyImage';

export const Card = (props) => {
	const { className, image } = props;

	return (
		<div className={clsx(['relative inline-block bg-white-100 rounded-3xl overflow-hidden card', className ])} >
			<LazyImage image={image} className="card__image" />
			<div className="card__slider-wrapper absolute bottom-0 flex flex-col items-center justify-center w-full px-20 align-middle bg-white-30 backdrop-filter backdrop-blur-md rounded-t-3xl">
				<RangeSlider {...props}/> 
			</div>
		</div>
	);
};