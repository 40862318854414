import React from 'react';

export const FaceSad = ({ ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 333 260.199">
		<g transform="translate(-507.75 -125.012)">
			<g transform="translate(507.75 193)" fill="#fff" stroke="#000" strokeWidth="2">
				<circle cx="76.5" cy="76.5" r="76.5" stroke="none"/>
				<circle cx="76.5" cy="76.5" r="75.5" fill="none"/>
			</g>
			<g transform="translate(687.75 193)" fill="#fff" stroke="#000" strokeWidth="2">
				<circle cx="76.5" cy="76.5" r="76.5" stroke="none"/>
				<circle cx="76.5" cy="76.5" r="75.5" fill="none"/>
			</g>
			<ellipse cx="41.5" cy="42" rx="41.5" ry="42" transform="translate(550.75 225.677)"/>
			<circle cx="42" cy="42" r="42" transform="translate(722.75 225.677)"/>
		</g>
		<path d="M345.894,385.019c8.852-9.278,23.454-15.338,39.968-15.338,15.107,0,28.614,5.07,37.583,13.037" transform="translate(-311.27 -154.353)" fill="#f8ba7b" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="3"/>
		<g transform="translate(-507.75 -125.012)">
			<path d="M791.942,356.275A8.686,8.686,0,1,1,782.12,370.6c-25.423-17.565-50.5-2.773-50.788-2.6a8.671,8.671,0,0,1-8.955-14.85C723.763,352.289,757.391,332.528,791.942,356.275Z" transform="translate(-256.336 148.966) rotate(-25)" fill="#020202"/>
			<path d="M73.765,11.652a8.686,8.686,0,1,1-9.822,14.329c-25.423-17.565-50.5-2.773-50.788-2.6A8.67,8.67,0,0,1,4.2,8.532C5.586,7.665,39.213-12.1,73.765,11.652Z" transform="translate(743.634 127.128) rotate(21)" fill="#020202"/>
		</g>
		<g id="Mouth" transform="translate(-507.75 -125.012)">
			<path d="M742.136,412.679s17.9,19.116,37.018,0" transform="matrix(-0.951, -0.309, 0.309, -0.951, 1282.245, 1005.243)" fill="none" stroke="#020202" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
		</g>
	</svg>
);
