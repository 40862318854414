import React from 'react';

export const SunOutline = ({color='#fff', fill='#fff', filled, ...rest}) => (<svg
	{...rest}
	xmlns="http://www.w3.org/2000/svg"
	width="19.484"
	height="19.484"
	viewBox="0 0 19.484 19.484"
>
	<path d="M-788.263,989.8l1.152-3.592a.17.17,0,0,1,.332.052l-.014,3.773a.17.17,0,0,0,.308.1l2.206-3.061a.17.17,0,0,1,.3.153l-1.179,3.584a.17.17,0,0,0,.262.19l3.044-2.229a.17.17,0,0,1,.237.238l-2.229,3.044a.17.17,0,0,0,.19.262l3.584-1.179a.17.17,0,0,1,.153.3l-3.06,2.206a.17.17,0,0,0,.1.308l3.773-.014a.17.17,0,0,1,.053.332l-3.592,1.152a.17.17,0,0,0,0,.324l3.592,1.152a.17.17,0,0,1-.053.332l-3.773-.014a.17.17,0,0,0-.1.308l3.06,2.206a.17.17,0,0,1-.153.3l-3.584-1.179a.17.17,0,0,0-.19.262l2.229,3.044a.17.17,0,0,1-.237.238l-3.044-2.229a.17.17,0,0,0-.262.19l1.179,3.584a.17.17,0,0,1-.3.153l-2.206-3.06a.17.17,0,0,0-.308.1l.014,3.773a.17.17,0,0,1-.332.053l-1.152-3.592a.17.17,0,0,0-.324,0l-1.152,3.592a.17.17,0,0,1-.332-.053l.014-3.773a.17.17,0,0,0-.308-.1l-2.206,3.06a.17.17,0,0,1-.3-.153l1.179-3.584a.17.17,0,0,0-.262-.19l-3.044,2.229a.17.17,0,0,1-.237-.238L-793,999.1a.17.17,0,0,0-.19-.262l-3.584,1.179a.17.17,0,0,1-.152-.3l3.06-2.206a.17.17,0,0,0-.1-.308l-3.773.014a.17.17,0,0,1-.052-.332l3.592-1.152a.17.17,0,0,0,0-.324l-3.592-1.152a.17.17,0,0,1,.052-.332l3.773.014a.17.17,0,0,0,.1-.308l-3.06-2.206a.17.17,0,0,1,.152-.3l3.584,1.179a.17.17,0,0,0,.19-.262L-795.234,989a.17.17,0,0,1,.237-.238l3.044,2.229a.17.17,0,0,0,.262-.19l-1.179-3.584a.17.17,0,0,1,.3-.153l2.206,3.061a.17.17,0,0,0,.308-.1l-.014-3.773a.17.17,0,0,1,.332-.052l1.152,3.592A.17.17,0,0,0-788.263,989.8Z"
		transform="translate(798.167 -985.833)"
		fill={filled ? color : fill}
		stroke={color}
		strokeWidth="0.5"
	/>
</svg>
);
