import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Meta = ({
	lang,
	title = 'Gå på opdagelse i Kenyakortet',
	description = 'Velkommen til det interaktive Kenya-kort.',
	ogTitle = 'Gå på opdagelse i Kenyakortet',
	ogDescription = 'Velkommen til det interaktive Kenya-kort.',
}) => {
	const { site } = useStaticQuery(
		graphql`
				query {
					site {
						siteMetadata {
							title
							description
						}
					}
				}
			`,
	);

	const metaDescription = description || site.siteMetadata.description;
	const facebookDescription = ogDescription || metaDescription; 
	const defaultTitle = site?.siteMetadata?.title || title;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: ogTitle,
				},
				{
					property: 'og:description',
					content: facebookDescription,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: 'https://a.storyblok.com/f/126918/1200x630/8bcab81bfe/kenyakort_social.jpg',
				},
				{
					property: 'twitter:image',
					content: 'https://a.storyblok.com/f/126918/1200x630/8bcab81bfe/kenyakort_social.jpg',
				},
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:creator',
					content: 'yellow umwelt',
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
			]}
		>
			<link rel="stylesheet" href="https://use.typekit.net/ogg5vgp.css"></link>
		</Helmet>
	);
};

export default Meta;