import React, { useContext } from 'react';
import { Button } from './buttons';
import LazyImage from './lazyImage';
import { Text } from './typography';
import GlobalContext from '../context/global-context';

export const Footer = ({ blok }) => {
	const context = useContext(GlobalContext);

	return (
		<div className="relative flex items-center min-h-screen py-34">
			<LazyImage className="absolute top-0 left-0 z-0 w-full h-full" image={blok.background_image} />
			<div className="z-10 grid grid-cols-12 mx-auto w-92p max-w-grid">
				<div className="col-span-8 text-white-100">
					<Text type="heading1" className="mb-72">{blok.title} </Text>
					<Text type="body1" className="mb-72">{blok.body}</Text>
					<Button variant="linkButtonLarge" colors="ws" to="/explore">{context.tr('backToMap')}</Button>
				</div>
			</div>
		</div>
	);
};
