import React from 'react';

export const FolkekirkenIcon = ({ color, ...rest }) => (
	<svg {...rest} xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 82.661 25.331">
		<path d="M5.031 8.006a12.658 12.658 0 11-.01 9.307c.76 0 1.5-.013 2.23.015.1 0 .217.192.287.317a10.3 10.3 0 007.74 5.409A10.5 10.5 0 107.601 7.602a.667.667 0 01-.717.413c-.603-.035-1.209-.009-1.853-.009z"/>
		<path d="M27.125 12.679a13.947 13.947 0 01-10.39 5.675 14.536 14.536 0 01-11.018-4.215l-4.209 4.13L0 16.847l4.005-4.177L.058 8.525l1.513-1.394 4.144 4.061c3.9-3.54 8.376-5.083 13.588-3.81a13.805 13.805 0 017.822 5.297zm-2.7-.015c-3.949-4.416-12.1-5.116-17.131.008 5.15 5.194 13.301 4.325 17.131-.012z"/>
		<path d="M61.002 13.684v.958h-1.88v.882h1.742v.937h-1.733v.973h1.922v.96h-3.136l-.045-.655c-.345 0-.692-.016-1.036.011a.361.361 0 00-.232.237c-.089.377-.315.479-.676.434a6.624 6.624 0 00-.766-.005c.087-.208.154-.373.225-.537.558-1.292 1.122-2.581 1.669-3.877a.454.454 0 01.5-.325c1.14.016 2.278.007 3.446.007zm-3.154 3.126v-1.92h-.057l-.74 1.9c.113.012.179.024.245.025.181-.006.361-.006.554-.006z"/>
		<path d="M41.619 13.712c.172-.589.663-.422 1.1-.415.012.038.035.076.025.088a1.172 1.172 0 00-.046 1.5 2.431 2.431 0 01-1.477 3.554 6.387 6.387 0 01-1.337.019c-.117 0-.316-.025-.337.023-.238.532-.693.282-1.062.335-.012-.041-.035-.08-.025-.091.392-.458.34-.818 0-1.365a2.511 2.511 0 01.155-2.938 2.412 2.412 0 012.639-.762c.1.02.19.046.286.067.01.006.025-.006.079-.015zm-.607.955a1.122 1.122 0 00-1.331.264 1.993 1.993 0 00-.12 2.02zm-.851 2.817a1.12 1.12 0 001.274-.372 1.882 1.882 0 00.11-1.8z"/>
		<path d="M77.958 11.655c-.416 0-.8.023-1.172-.012a.561.561 0 01-.36-.266c-.4-.71-.776-1.434-1.163-2.152a.867.867 0 00-.278-.33v2.735h-1.026v-4.7c.416 0 .821-.017 1.224.011.1.007.228.138.285.244.4.729.776 1.466 1.162 2.2a.967.967 0 00.272.345V6.957h1.057z"/>
		<path d="M37.52 18.416c-.41 0-.792.024-1.168-.013a.565.565 0 01-.357-.273c-.4-.71-.779-1.434-1.166-2.153a.842.842 0 00-.276-.327v2.74h-1.02v-4.7c.421 0 .827-.017 1.23.012.1.007.224.143.282.25.4.729.777 1.467 1.165 2.2a.927.927 0 00.271.339v-2.776h1.039z"/>
		<path d="M39.373 6.819a2.267 2.267 0 012.406 2.49 2.426 2.426 0 11-4.851-.037 2.269 2.269 0 012.445-2.453zm-1.2 2.6a3.328 3.328 0 00.059.417 1.1 1.1 0 001.1.966 1.067 1.067 0 001.134-.969 3.447 3.447 0 00-.087-1.355 1 1 0 00-1.205-.672c-.657.113-1.01.638-1.009 1.614z"/>
		<path d="M63.92 9.766l1.195 1.888c-.463 0-.851.017-1.235-.011a.49.49 0 01-.3-.23 12.36 12.36 0 01-.646-1.136.514.514 0 00-.718-.289v1.637h-1.182V6.866c.93.064 1.866.078 2.785.21a1.167 1.167 0 011 1.109 1.315 1.315 0 01-.7 1.452c-.063.032-.117.075-.199.129zm-1.7-.745a8.65 8.65 0 00.889-.006.519.519 0 00.5-.569.488.488 0 00-.476-.539c-.3-.023-.6-.005-.914-.005z"/>
		<path d="M43.532 18.395v-4.757a21.168 21.168 0 012.487.163 2.045 2.045 0 011.683 2.325 2.117 2.117 0 01-1.993 2.257c-.708.071-1.428.012-2.177.012zm1.189-.96c.869.094 1.294-.045 1.541-.485a2.023 2.023 0 00-.061-1.893 1.592 1.592 0 00-1.48-.409z"/>
		<path d="M52.312 18.4h-1.171v-1.916h-1.672v1.9h-1.182v-4.675h1.156v1.735h1.692v-1.737h1.177z"/>
		<path d="M69.742 11.633c-1.354.082-1.354.082-2.023-1l-.566-.912c-.82.453-.455 1.23-.574 1.9h-1.14V6.959h1.153v1.734l.1.046a8.78 8.78 0 00.924-1.106c.467-.782 1.149-.8 1.974-.662-.473.541-.913 1.064-1.378 1.564a.434.434 0 00-.058.647c.541.788 1.041 1.599 1.588 2.451z"/>
		<path d="M47.138 11.634h-1.167v-4.68h1.155v1.75l.1.048c.348-.409.729-.794 1.034-1.233a1.217 1.217 0 011.306-.6 4.306 4.306 0 00.607 0l-1.682 1.926 1.8 2.8c-.474 0-.885.014-1.293-.011-.092-.006-.2-.136-.259-.233-.355-.556-.7-1.12-1.064-1.711-.253.262-.569.434-.538.86.021.354.001.704.001 1.084z"/>
		<path d="M56.171 9.685c-.263.281-.564.469-.519.9a10.393 10.393 0 01.007 1.042h-1.168V6.963h1.164v1.742l.09.031c.387-.455.8-.894 1.154-1.372a.955.955 0 01.961-.441 7.363 7.363 0 00.815.005l-1.68 1.927 1.8 2.8c-.474 0-.875.018-1.272-.012-.1-.008-.224-.144-.292-.25-.353-.556-.695-1.119-1.06-1.708z"/>
		<path d="M82.572 7.656l-.674.571a9.919 9.919 0 00-1.13-.43 1.2 1.2 0 00-.657.072.47.47 0 00-.236.346.4.4 0 00.217.311c.286.11.594.164.891.245a8.474 8.474 0 01.848.246 1.188 1.188 0 01.827 1.113 1.346 1.346 0 01-.737 1.361 3.173 3.173 0 01-3.51-.589l.586-.59c.6.185 1.094.353 1.6.48a.734.734 0 00.489-.08c.142-.079.319-.242.325-.376a.543.543 0 00-.3-.389 6.12 6.12 0 00-.849-.24 7.907 7.907 0 01-.844-.26 1.2 1.2 0 01-.792-1.186 1.27 1.27 0 01.763-1.214 2.838 2.838 0 013.183.609z"/>
		<path d="M51.765 7.893v.875h1.857v.935H51.77v.99h2.071v.931h-3.28V6.952h3.226v.942z"/>
		<path d="M71.245 8.779h1.856v.924h-1.854v.991h2.07v.94c-.142.008-.265.02-.388.02h-2.891v-4.7h3.193v.935h-1.986z"/>
		<path d="M66.495 16.915v1.475h-1.168v-4.755c.91.056 1.825.044 2.714.189a1.327 1.327 0 011.09 1.495 1.4 1.4 0 01-1.111 1.49 4.008 4.008 0 01-.75.1c-.235.02-.473.006-.775.006zm.021-.917c.48-.1 1.028.19 1.3-.348a.842.842 0 00-.051-.743c-.321-.413-.811-.229-1.251-.244z"/>
		<path d="M33.526 11.628V6.953h3.126v.93h-1.926v1.039h1.783v.924h-1.806v1.783z"/>
		<path d="M45.513 10.629v1h-3.162V6.957h1.174v3.674z"/>
		<path d="M61.711 13.694h1.141v3.693h2.006v1.006h-3.146z"/>
		<path d="M52.812 18.401v-.923c1.344-.094 1.069-.333 1.089-1.272.018-.826 0-1.653 0-2.5h1.19v3.017c0 1.3-.546 1.809-1.855 1.726-.138-.008-.274-.03-.424-.048z"/>
		<path d="M59.085 6.945h1.138v4.677h-1.138z"/>
		<path d="M42.328 24.658a1.128 1.128 0 01-1.52-.128 1.4 1.4 0 01-.059-1.682 1.134 1.134 0 011.47-.284c.047.023.125-.021.189-.031.171-.025.342-.047.538-.073v2.4l-.594-.093zm.071-1.008a.662.662 0 10-1.314-.018.666.666 0 101.314.018z"/>
		<path d="M35.788 24.89c-.212-.061-.382-.123-.557-.156a.432.432 0 00-.242.048 1.118 1.118 0 01-1.369-.3 1.382 1.382 0 01-.028-1.616 1.109 1.109 0 011.356-.364c.139.066.221.128.38 0 .093-.073.291-.013.461-.013zm-.549-1.239a.66.66 0 00-.658-.752.667.667 0 00-.641.732.689.689 0 00.641.78.679.679 0 00.657-.759z"/>
		<path d="M48.746 24.886l-.681-.17a1.02 1.02 0 01-1.284-.047 1.214 1.214 0 01-.452-1.278 1.129 1.129 0 01.93-.967.539.539 0 01.29-.015 1.841 1.841 0 001.2.042zm-1.194-.474a.706.706 0 00.663-.77.666.666 0 10-1.324 0 .7.7 0 00.661.769z"/>
		<path d="M54.776 23.95a2.15 2.15 0 00.53.437.66.66 0 00.822-.363l.44.146a1.161 1.161 0 01-1.54.663 1.254 1.254 0 01-.773-1.509 1.228 1.228 0 011.378-.916c.676.1 1.02.587.949 1.4H54.85zm1.256-.565a.554.554 0 00-.659-.494.485.485 0 00-.506.494z"/>
		<path d="M51.389 24.82h-.585v-1.273c0-.329-.079-.6-.463-.631-.32-.029-.533.2-.55.605s0 .841 0 1.29h-.549v-2.382c.2.061.367.121.536.157a.378.378 0 00.229-.038.87.87 0 011.381.788c.016.488.001.977.001 1.484z"/>
		<path d="M54.044 23.169c-.236.105-.433.269-.667-.07a.608.608 0 00-.873 0 .82.82 0 00-.062 1.045.672.672 0 00.911.123 3.691 3.691 0 00.3-.28l.4.146a1.085 1.085 0 01-1.238.774 1.261 1.261 0 01-.1-2.489 1.108 1.108 0 011.329.751z"/>
		<path d="M38.052 23.972l.435.171a1.136 1.136 0 01-1.309.754 1.262 1.262 0 01-.034-2.484 1.13 1.13 0 011.321.741c-.222.122-.407.29-.65-.042a.623.623 0 00-.907 0 .853.853 0 00-.034 1.05.683.683 0 00.916.09 2.805 2.805 0 00.262-.28z"/>
		<path d="M39.265 22.993l-.665-.03-.021-.462.659-.051.042-.73h.53l.047.74.629.031v.466l-.64.039v1.819h-.581z"/>
		<path d="M43.962 24.829h-.528v-3.111h.528z"/>
		<path d="M44.976 24.829h-.527v-3.111h.527z"/>
		<path d="M45.981 24.828h-.52v-2.326h.52z"/>
		<path d="M45.982 22.214h-.529v-.491h.529z"/>
		<path d="M19.045 12.658a1.288 1.288 0 111.269 1.3 1.263 1.263 0 01-1.269-1.3z"/>
	</svg>
);
