import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import clsx from 'clsx';
import { Text } from './typography';
import { AnimationCurve2, Play } from './svgs';
import { richTextResolver } from '../utils/richTextResolver';
import LazyImage from './lazyImage';

export const VideoSection = ({ blok }) => {
	const containerRef = useRef();
	const videoRef = useRef();
	const playBtnRef = useRef();
	const [isPlaying, setIsPlaying] = useState(false);
	const [offset, setOffset] = useState(0);
	const handlePlaying = () => setIsPlaying(true);
	const handlePause = () => setIsPlaying(false);
	const animatedTextRef = useRef(null);
	const [bodyHtml, setBodyHtml ] = useState(null);

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		const html = richTextResolver(blok.body);
		setBodyHtml(html);

		const o = { offset: offset };
		const textElement = animatedTextRef.current.querySelector('text');
		const endOffset = animatedTextRef.current.offsetWidth - textElement.getBoundingClientRect().width - 50;

		const offsetAnimation = gsap.to(o, {
			offset: endOffset,
			scrollTrigger: {
				trigger: containerRef.current,
				start: 'top center',
				onUpdate: (trigger) => { 
					setOffset(o.offset * trigger.progress); 
				},
			},
		});

		return () => offsetAnimation.kill();
	}, []);

	useEffect(() => {
		if(playBtnRef.current) {
			const animation = gsap.to([playBtnRef.current, animatedTextRef.current], {
				opacity: !isPlaying * 1,
				duration: .4,
			});

			return () => animation.kill();
		}
	}, [isPlaying]);

	const handlePlay = () => {
		if (videoRef && videoRef.current) videoRef.current.play();
	};

	return (
		<section ref={containerRef} className="relative grid grid-cols-12 mx-auto video-section my-124 max-w-grid w-92p">
			<div className="relative col-start-1 col-end-13 bg-primary rounded-3xl">
				{blok.video?.filename ?
					<>
						<video
							ref={videoRef}
							onPause={handlePause}
							onPlaying={handlePlaying}
							poster={blok?.videoPoster?.filename || null}
							className="w-full overflow-hidden rounded-3xl"
							playsInline
							preload="auto"
							controls
						>
							<source src={blok.video.filename} type="video/mp4"/>
						</video>
						<div
							ref={playBtnRef}
							onClick={handlePlay}
							className={clsx(['absolute -mt-64 -ml-64 opacity-100 top-1/2 left-1/2 w-128',
								`${isPlaying ? 'cursor-default' : 'cursor-pointer'}`])}
						><Play /></div>
					</>
					:
					<div className='relative w-full overflow-hidden pb-50p rounded-3xl' ><LazyImage className="absolute w-full transform top-50p left-50p -translate-y-50p -translate-x-50p" image={blok.video_poster} /></div>
				}
				<div ref={animatedTextRef} className='absolute left-0 z-10 w-full -bottom-64'><AnimationCurve2 text={blok?.animated_text} offset={offset} fontSize='48' /></div>
			</div>
			<Text type="body1HTML" className="col-start-2 col-end-8 mt-124 text-primary" html={bodyHtml}></Text>
		</section>
	);
};
