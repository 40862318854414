import React, { useContext, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { FolkekirkenIcon, KenyaMapIcon, Buk, CloudLg, CloudSm } from './svgs';
import { Text } from './typography';
import { Arrow } from './svgs';
import GlobalContext from '../context/global-context';
import { isBrowser } from '../helpers/isBrowser';

export const PrintSection = ({ blok, slug }) => {
	const context = useContext(GlobalContext);
	const containerRef = useRef(null);
	const cloud1Ref =  useRef(null);
	const cloud2Ref =  useRef(null);

	const handleClick = () => {
		if(isBrowser()){
			window.open(
				`/print/Kenyakortet_${slug}`,
				'_blank',
				{},
			);
		}
	};

	useEffect(() => {
		if(cloud1Ref) {
			gsap.to(cloud1Ref.current, {
				left: '32%',
				y: 10,
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'bottom 30%',
					scrub: true,
				},
			});
		}

		if(cloud2Ref) {
			gsap.to(cloud2Ref.current, {
				left: '40%',
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'bottom 30%',
					scrub: true,
				},
			});
		}

		return () => {
			gsap.killTweensOf(cloud1Ref.current);
			gsap.killTweensOf(cloud2Ref.current);
		};

	}, []);

	return (
		<section ref={containerRef} className="relative grid grid-cols-12 gap-24 mx-auto my-124 max-w-grid w-92p">
			<div className="col-span-6">
				<div className="w-full pt-24 shadow-lg px-30 pb-51">
					<div>
						<Buk className="inline-block w-57"/>
						<FolkekirkenIcon className="inline-block ml-30 w-63"/>
					</div>
					<div className="pb-10 mx-auto mb-64 mt-124 w-75p">
						<img src={blok.illustration.filename} width="100%"/>
					</div>
					<Text type="country" className="block pb-24 text-center">{blok.image_title}</Text>
					<div className="block pb-32 mx-auto text-center text-black text-regular w-66p font-15 font-playFair">{blok.image_text}</div>
					<div className="m-auto w-110">
						<KenyaMapIcon className="w-full"/>
					</div>
				</div>
			</div>
			<div className="flex items-center col-span-4 col-start-8">
				<div className="w-full text-primary">
					<Text type="heading2" className="mb-36">{blok.title}</Text>
					<Text type="body1">{blok.body}</Text>
					<button className="flex cursor-pointer mt-36 transition-on-hover" onClick={handleClick}>
						<span className="font-nove text-12">{context.tr('printOut')}</span>
						<Arrow  className="ml-20 transform rotate-180 arrow-icon" width={14}/>
					</button>
				</div>
			</div>
			<div className="absolute w-128 top-36 left-60p" ref={cloud1Ref}>
				<CloudLg width={110} />
			</div>
			<div className="absolute w-128 top-100 left-80p" ref={cloud2Ref}>
				<CloudSm width={73} />
			</div>
		</section>
	);
};
