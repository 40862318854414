import React from 'react';

export const BigArrowBackIcon = ({ ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.525 17.606" { ...rest }>
		<g id="Path_4845" data-name="Path 4845" fill="#fff" strokeLinecap="round" strokeLinejoin="round">
			<path d="M17052.381-2144.866h-8.723v-5.062l-7.8 7.8 7.8 7.8v-4.767h8.723Z" transform="translate(-17034.855 2150.929)"/>
			<path d="m17043.658-2149.929-7.803 7.804 7.803 7.803v-4.768h8.723v-5.776h-8.723v-5.063m0-1a.997.997 0 0 1 1 1v4.063h7.723a1 1 0 0 1 1 1v5.776a1 1 0 0 1-1 1h-7.723v3.768a1 1 0 0 1-1.707.707l-7.803-7.803a1 1 0 0 1 0-1.415l7.803-7.803a1 1 0 0 1 .707-.293Z" fill="#000" transform="translate(-17034.855 2150.929)"/>
		</g>
	</svg>
);
