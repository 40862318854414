import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


const LazyImage = ({ image, className, ...rest }) => {
	if (!image) { return <></>; }
	const { filename: src, alt: altText } = image;
	
	return <LazyLoadImage {...rest} src={src} alt={altText} effect="opacity" className={className} />;
};

export default LazyImage;
