import React, { useContext } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import GlobalContext from '../context/global-context';

// PlaySmall import from svgs
import { BigArrowBackIcon, MapIcon, Buk, FolkekirkenIcon, OleKirkLogo, PlaySmall } from './svgs';
import useLocalStorage from '../hooks/useLocalstorage';
import { Button } from './';

export const Header = ({ color = 'black', hasBack = true, isMapPage = false }) => {
	const classes = isMapPage ? 'w-full px-40' : ' max-w-grid w-92p';
	const [role] = useLocalStorage('FKN_Role', '');
	const { tr } = useContext(GlobalContext);

	return (
		<div className="header">
			<div className={clsx('header__inner-wrapper my-32 mx-auto', classes)}>
				<div className="header__logos-wrapper">
					<a target="_blank" href="https://2021.u-landskalender.dk/" rel="noreferrer">
						<Buk className='header__logo header__logo--buk' color={color}/>
					</a>
					<a target="_blank" href="https://www.noedhjaelp.dk/" rel="noreferrer">
						<FolkekirkenIcon className='header__logo header__logo--folkekirken' color={color} />
					</a>
					<OleKirkLogo fill={color} className='header__logo header__logo--olekirk' />
				</div>
				{isMapPage && <div className="flex items-center ml-auto">
					<Link to="/liveshow" className="flex items-center text-white-100 font-nove text-12">
						<PlaySmall className="-mt-2"/>
						<span className="ml-4">{tr('previousLiveShows')}</span>
					</Link>
					<a target="_blank" href="https://2021.u-landskalender.dk/" className="ml-64 text-white-100 font-nove text-12" rel="noreferrer">ulandskalenderen</a>
					{role === 'host' && <Button variant="linkButton" className="ml-64" to="/laerer/klasse">{tr('yourClass')}</Button>}
				</div>}
				{hasBack && <Link to="/explore" className='header__button'>
					<BigArrowBackIcon className='header__button-icon header__button-icon--arrow' />
					<MapIcon className='header__button-icon' />
				</Link>}
			</div>
		</div>
	);
};
