import React, { useEffect, useRef, useState, useContext } from 'react';
import { gsap } from 'gsap';

import nf from '../utils/numberFormatter';
import { Text } from './typography';
import { Arrow } from './svgs';
import GlobalContext from '../context/global-context';

export const AnswerIndicator = ({ title = '', units = '', user_answer, answer, answer_max }) => {
	const context = useContext(GlobalContext);
	const [answerCounter, setAnswerCounter] = useState(0);
	const [userAnswerCounter, setUserAnswerNumber] = useState(0);
	const userAnswerBallRef = useRef();
	const containerRef = useRef();
	const indicatorRef = useRef();

	const resultTextRef = useRef();
	const correctResultBallRef = useRef();
	const resultIndicatorRef = useRef();

	const inBetween = (x, min, max) => ((x-min)*(x-max) <= 0);

	const getVerdict = () => {
		const result = (Math.abs(user_answer - answer) / answer) * 100;

		if(inBetween(result, 0, 19)) return context.tr('perfect');
		if(inBetween(result, 20, 39)) return context.tr('almost');
		if(inBetween(result, 40, 59)) return context.tr('notQuite');
		if(inBetween(result, 60, 79)) return context.tr('wrong');
		return context.tr('completelyWrong');
	};

	useEffect(() => {
		const duration = 2;
		const tl = gsap.timeline();
		let usercount = { count: 0 };
		let correctcount = { count: 0 };

		const containerWidth = Math.ceil(containerRef.current.offsetWidth);
		const offsetUserAnswer = - Math.ceil(24 * user_answer / answer_max);
		const offsetCorrectAnswer = - Math.ceil(24 * answer / answer_max);

		// aligns the arrow
		const indicatorOffset = 7;

		const tlAnimation = tl
			.to(userAnswerBallRef.current, {
				left: offsetUserAnswer + (user_answer / answer_max * containerWidth),
				ease: 'circ.in',
				duration: duration,
			})
			.to(usercount, {
				count: user_answer,
				roundProps: 'count',
				duration: duration,
				ease: 'circ.in',
				onUpdate: () => setUserAnswerNumber(usercount.count),
			}, '<')
			.to(indicatorRef.current, {
				translateX: offsetUserAnswer + (user_answer / answer_max * containerWidth) + indicatorOffset,
				duration: duration,
				ease: 'circ.in',
			}, '<')
			.to([resultIndicatorRef.current, correctResultBallRef.current], {
				opacity: 1,
				duration: .8,
			})
			.to(resultIndicatorRef.current, {
				translateX: offsetCorrectAnswer + (answer / answer_max * containerWidth) + indicatorOffset,
				duration: duration,
				ease: 'circ.in',
			})
			.to(correctcount, {
				count: answer,
				roundProps: 'count',
				duration: duration,
				ease: 'circ.in',
				onUpdate: () => setAnswerCounter(correctcount.count),
			}, '<')
			.to(correctResultBallRef.current, {
				left: offsetCorrectAnswer + (answer / answer_max * containerWidth),
				ease: 'circ.in',
				duration: duration,
			}, '<')
			.fromTo(resultTextRef.current, {
				opacity: 0,
				scale: 1,
			}, {
				opacity: 1,
				scale: 2,
				duration: .3,
			})
			.to(resultTextRef.current, {
				opacity: 1,
				scale: 1,
				duration: .8,
				ease: 'elastic',
			});

		return () => {
			gsap.killTweensOf(userAnswerBallRef.current);
			tlAnimation.kill();
		};
	}, []);

	return (
		<div className="mb-36 text-primary answer-indicator">
			<div className="flex pb-7">
				<Text type="body1">{title}</Text>
			</div>
			<div className="w-full grid-answers">
				<div className="relative col-span-4 col-start-2 mt-16 -mb-8">
					<div ref={resultIndicatorRef} className="flex items-start -mt-10 transform translate-x-0 opacity-0">
						<Arrow width={17} fill="#BE4FF0" className="transform scale -scale-y-1"/>
						<Text type="answer_indicator" className="ml-4 text-accents-purple">{context.tr('correctAnswer')}: {`${nf(answerCounter)} ${units}`}</Text>
					</div>
				</div>

				<p className="self-center col-start-1 mr-8 font-bold text-20 font-playFair text-white-100">0</p>
				<div className="flex items-center col-span-4">
					<div ref={containerRef} className="relative w-full h-24 overflow-hidden rounded-full bg-white-100">
						<div ref={userAnswerBallRef} className="absolute left-0 w-24 h-24 bg-primary rounded-xl"></div>
						<div ref={correctResultBallRef} className="absolute left-0 w-24 h-24 opacity-0 bg-accents-purple rounded-xl"></div>
					</div>
				</div>

				<p className="self-center col-span-1 col-start-6 ml-16 font-bold text-20 font-playFair text-white-100">{`${answer_max ? nf(answer_max) : 100} ${units}`}</p>
				<div ref={resultTextRef} className="flex items-center col-span-2 col-start-8 ml-auto text-right opacity-0">
					<Text type="heading2" className="text-primary">{getVerdict()}</Text>
				</div>
				<div className="relative col-span-4 col-start-2 pt-8">
					<div ref={indicatorRef} className="flex items-start -mt-10 transform translate-x-0">
						<Arrow width={17}/>
						<Text type="answer_indicator" className="ml-4">{context.tr('youAnswered')}: {`${nf(userAnswerCounter)} ${units}`}</Text>
					</div>
				</div>
			</div>
		</div>
	);
};
