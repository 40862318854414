import React, { useState, useEffect } from 'react';
import { isBrowser } from '../helpers/isBrowser';
import { Header, Meta, MobileWarning } from '.';

const Layout = ({ children, ...rest }) => {
	const [isDesktop, setDesktop] = useState(true);
 
	const checkWindowSize = () => {
		if(isBrowser() &&  window.innerWidth < 768) {
			setDesktop(false);
		}

		if(isBrowser() && window.innerWidth >= 768) {
			setDesktop(true);
		}
	};

	useEffect(() => {
		if(isBrowser()) {
			checkWindowSize();
			window.addEventListener('resize', checkWindowSize);
			return () => window.removeEventListener('resize', checkWindowSize);
		}
	}, []);

	if(isBrowser() && !isDesktop) {
		return (
			<main>
				<Meta title="Skift venligst til Ipad eller computer" description="Skift venligst til Ipad eller computer" />
				<Header hasBack={false} color='#ffffff' />
				<MobileWarning />
			</main>
		);
	}

	return (
		<main {...rest}>
			{children}
		</main>
	);
};

export default Layout;