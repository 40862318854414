import * as THREE from 'three';

export const themes = [
	{
		theme: 'themeTech',
		questions: [
			{
				link: 'mobilpenge',
				position: [113, 5, -51],
				minimapPosition: [50, 101],
			},
			{
				link: 'mobiltelefon',
				position: [-68, 6, -152],
				minimapPosition: [154, 40],
			},
		],
	},
	{
		theme: 'themeFamily',
		questions: [
			{
				link: 'sprog',
				position: [-68, 6, -189],
				minimapPosition: [179, 6],
			},
			{
				link: 'skole',
				position: [26, 6, -16],
				minimapPosition: [190, 102],
			},
			{
				link: 'born',
				position: [-114, 15, -46],
				minimapPosition: [104, 108],
			}],
	},
	{
		theme: 'themeFood',
		questions: [
			{
				link: 'kod',
				position: [-11, 6, -87],
				minimapPosition: [214, 205],
			},
			{
				link: 'kiks',
				position: [-130, 7, 120],
				minimapPosition: [138, 87],
			},
		],
	},
	{
		theme: 'themeTransport',
		questions: [
			{
				link: 'busser',
				position: [28, 4.3, 7],
				minimapPosition: [109, 140],
			},
			{
				link: 'bilfri',
				position: [128, 5, 87],
				minimapPosition: [43, 185],
			},
		],
	},
	{
		theme: 'themeAnimals',
		questions: [
			{
				link: 'grise',
				position: [0, 4, 73],
				minimapPosition: [76, 46],
			},
			{
				link: 'fugle',
				position: [53, 4, 141],
				minimapPosition: [135, 176],
			},
			{
				link: 'slanger',
				position: [160, 6, 135],
				minimapPosition: [42, 205],
			},
			{
				link: 'kamel',
				position: [121, 3.88, -151],
				minimapPosition: [98, 228],
			},
		],
	},
	{
		theme: 'themeSociety',
		questions: [
			{
				link: 'byen',
				position: [104, 10, -80],
				minimapPosition: [170, 136],
			},
			{
				link: 'landet',
				position: [-79, 8, 18],
				minimapPosition: [146, 92],
			},
			{
				link: 'boldsport',
				position: [-35, 9, -79],
				minimapPosition: [69, 81],
			},
		],
	},
];

const markers = (scene, gltfloader, textureLoader) => {
	/* TEXTURES */
	const themeAnimals_texture = textureLoader.load('/themeAnimalsTextureSmall.jpg');
	themeAnimals_texture.encoding = THREE.sRGBEncoding;
	themeAnimals_texture.flipY = false;
	const themeAnimals_material = new THREE.MeshBasicMaterial({ map: themeAnimals_texture });

	const themeFamily_texture = textureLoader.load('/themeFamilyTextureSmall.jpg');
	themeFamily_texture.encoding = THREE.sRGBEncoding;
	themeFamily_texture.flipY = false;
	const themeFamily_material = new THREE.MeshBasicMaterial({ map: themeFamily_texture });

	const themeFood_texture = textureLoader.load('/themeFoodTextureSmall.jpg');
	themeFood_texture.encoding = THREE.sRGBEncoding;
	themeFood_texture.flipY = false;
	const themeFood_material = new THREE.MeshBasicMaterial({ map: themeFood_texture });

	const themeSociety_texture = textureLoader.load('/themeSocietyTextureSmall.jpg');
	themeSociety_texture.encoding = THREE.sRGBEncoding;
	themeSociety_texture.flipY = false;
	const themeSociety_material = new THREE.MeshBasicMaterial({ map: themeSociety_texture });

	const themeTech_texture = textureLoader.load('/themeTechTextureSmall.jpg');
	themeTech_texture.encoding = THREE.sRGBEncoding;
	themeTech_texture.flipY = false;
	const themeTech_material = new THREE.MeshBasicMaterial({ map: themeTech_texture });

	const themeTransport_texture = textureLoader.load('/themeTransportTextureSmall.jpg');
	themeTransport_texture.encoding = THREE.sRGBEncoding;
	themeTransport_texture.flipY = false;
	const themeTransport_material = new THREE.MeshBasicMaterial({ map: themeTransport_texture });

	const checkmark_texture = textureLoader.load('/done_textureSmall.jpg');
	checkmark_texture.encoding = THREE.sRGBEncoding;
	checkmark_texture.flipY = false;
	const checkmark_material = new THREE.MeshBasicMaterial({ map: checkmark_texture });

	const music_texture = textureLoader.load('/themeMusicSmall.jpg');
	music_texture.encoding = THREE.sRGBEncoding;
	music_texture.flipY = false;
	const music_material = new THREE.MeshBasicMaterial({ map: music_texture });

	/* MESHES */
	gltfloader.load('/themeAnimals.glb', gltf => {
		gltf.scene.children[0].material = themeAnimals_material;
		gltf.scene.children[0].name = 'themeAnimals';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeFamily.glb', gltf => {
		gltf.scene.children[0].material = themeFamily_material;
		gltf.scene.children[0].name = 'themeFamily';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeFood.glb', gltf => {
		gltf.scene.children[0].material = themeFood_material;
		gltf.scene.children[0].name = 'themeFood';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeSociety.glb', gltf => {
		gltf.scene.children[0].material = themeSociety_material;
		gltf.scene.children[0].name = 'themeSociety';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeTech.glb', gltf => {
		gltf.scene.children[0].material = themeTech_material;
		gltf.scene.children[0].name = 'themeTech';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeTransport.glb', gltf => {
		gltf.scene.children[0].material = themeTransport_material;
		gltf.scene.children[0].name = 'themeTransport';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/checkmark.glb', gltf => {
		gltf.scene.children[0].material = checkmark_material;
		gltf.scene.children[0].name = 'checkmark';
		gltf.scene.children[0].visible = false;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});

	gltfloader.load('/themeMusic.glb', gltf => {
		gltf.scene.children[0].material = music_material;
		gltf.scene.children[0].name = 'music';
		gltf.scene.children[0].visible = true;
		gltf.scene.children[0].castShadow = true;
		scene.add(gltf.scene.children[0]);
	});
};

export default markers;
