import React from 'react';

export const Buk = ({ color, ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill={color} width="75.07" height="22.925" viewBox="0 0 75.07 22.925" {...rest}>
		<g id="Group_29" data-name="Group 29" transform="translate(0 0)">
			<g id="Group_28" data-name="Group 28" transform="translate(0)">
				<path id="Path_16" data-name="Path 16" d="M1738.528,2081.076q-.713-1.062-1.426-2.123c-.06-.089-.107-.222-.19-.252a2.74,2.74,0,0,0-.75-.176c-.048,0-.158.272-.165.422-.021.508-.008,1.017-.008,1.539l-1.474-.082a5.115,5.115,0,0,0,.1-.7c-.005-1.421-.033-2.842-.033-4.263a8.95,8.95,0,0,1,.112-1.114c.023-.184.126-.259.332-.253.362.01.725-.014,1.087-.038a4.274,4.274,0,0,1,2.907.653,1.177,1.177,0,0,1,.551,1.059,10.228,10.228,0,0,1-.107,1.433,2.477,2.477,0,0,1-.427.873.409.409,0,0,0-.023.546c.076.1.133.221.214.319a4.413,4.413,0,0,1,.973,1.777v.385Zm-1.48-5.481v.019h-.448a.4.4,0,0,0-.455.419c-.005.106-.027.212-.033.319-.031.574.115.73.68.726a2,2,0,0,0,.447-.016,2.272,2.272,0,0,0,.654-.227.7.7,0,0,0,.275-.793c-.053-.3-.3-.368-.544-.415A4,4,0,0,0,1737.048,2075.595Z" transform="translate(-1665.128 -2058.15)"/>
				<path id="Path_17" data-name="Path 17" d="M1365.529,1827c.009.006.016.017.026.019a1.251,1.251,0,0,1,.9.652c.377.654.869,1.242,1.309,1.86.18.252.351.51.521.758v-3.008c.338,0,.607.02.872,0,.459-.041.593.126.57.577-.036.744-.013,1.492-.013,2.238,0,.394,0,.788.01,1.182.009.608.023,1.216.031,1.825.005.407-.028.4-.418.332a3.651,3.651,0,0,0-.984,0c-.275.029-.43-.008-.515-.311a2.229,2.229,0,0,0-.364-.669c-.48-.667-.975-1.322-1.465-1.981-.053-.072-.108-.142-.2-.268,0,.591-.014,1.109,0,1.626.016.448.061.9.11,1.342.035.316-.1.381-.39.359s-.563.028-.844.022a.934.934,0,0,1-.291-.1c.033-.728.093-1.421.088-2.112-.007-1.111-.058-2.222-.086-3.333a1.125,1.125,0,0,1,.357-1.006Z" transform="translate(-1318.779 -1827)"/>
				<path id="Path_18" data-name="Path 18" d="M1742.969,1827c.465.15.94.273,1.39.458a4.365,4.365,0,0,1,.714.459l-.693,1.418c-.049-.043-.128-.121-.215-.189a2.019,2.019,0,0,0-1.769-.444c-.172.04-.432.047-.4.323a.508.508,0,0,0,.463.422c.626.126,1.255.24,1.875.394.695.173.893.416.86,1.139a6.833,6.833,0,0,1-.235,1.381,1.018,1.018,0,0,1-.805.749c-.468.109-.938.215-1.411.293a2.953,2.953,0,0,1-2.361-.777c-.2-.16-.207-.319-.032-.512.243-.268.468-.552.683-.808.42.215.8.42,1.195.61a1.023,1.023,0,0,0,.987-.051.668.668,0,0,0,.319-.452.545.545,0,0,0-.331-.4c-.471-.132-.963-.186-1.441-.292a2.586,2.586,0,0,1-.816-.26,1.437,1.437,0,0,1-.574-2.2,2.094,2.094,0,0,1,1.4-1.136,1.74,1.74,0,0,0,.293-.122Z" transform="translate(-1670.34 -1827)"/>
				<path id="Path_19" data-name="Path 19" d="M654,1886.223c.35-.04.7-.085,1.049-.119.208-.02.358.053.366.294q.033,1,.065,2a1.06,1.06,0,0,1-.02.138l-1.174.281c-.057-.626-.1-1.178-.159-1.728a6.222,6.222,0,0,0-.127-.614Z" transform="translate(-654 -1882.305)"/>
				<path id="Path_20" data-name="Path 20" d="M1153.339,1829.286a4.048,4.048,0,0,1-.376.382c-.484.368-.519.412-.175.9a3.389,3.389,0,0,1,.483,2.7,2.144,2.144,0,0,1-.761,1.269,3.966,3.966,0,0,1-3.915.7c-.414-.123-.4-.132-.589.267a.285.285,0,0,1-.219.1c-.389.007-.779,0-1.168-.009-.057,0-.164-.038-.163-.053a.512.512,0,0,1,.062-.291,6.557,6.557,0,0,1,.594-.584.324.324,0,0,0,.062-.478.527.527,0,0,0-.067-.108,2.152,2.152,0,0,1-.455-2.209,3.046,3.046,0,0,1,3.2-2.675c.47,0,.939.044,1.409.045a1.327,1.327,0,0,0,.4-.129c.047-.017.1-.065.137-.057.237.051.469.132.708.17S1152.99,1829.262,1153.339,1829.286Zm-2.165,2.4a1.563,1.563,0,0,0-.2.162q-.772.913-1.539,1.831a.2.2,0,0,0,.1.349,2.185,2.185,0,0,0,.749.091,1.282,1.282,0,0,0,1.168-.907A1.707,1.707,0,0,0,1151.175,1831.681Zm-2.669,1.393a1.012,1.012,0,0,0,.276-.152c.552-.65,1.1-1.3,1.64-1.965.15-.185,0-.3-.165-.291a3.856,3.856,0,0,0-1.132.151,1.291,1.291,0,0,0-.66.6A2.329,2.329,0,0,0,1148.506,1833.074Z" transform="translate(-1114.832 -1828.921)"/>
				<path id="Path_21" data-name="Path 21" d="M1054.9,1831.549c.776,0,1.531,0,2.287,0a8.89,8.89,0,0,1,1.341.032,3,3,0,0,1,1.008.385,1.693,1.693,0,0,1,.342,2.042c-.226.382-.231.443.134.727a1.5,1.5,0,0,1,.534,1.493,1.558,1.558,0,0,1-1.146,1.371,7.683,7.683,0,0,1-1.69.325c-.777.049-1.561-.006-2.341-.008-.229,0-.322-.1-.332-.325-.052-1.133-.13-2.264-.168-3.4-.027-.821-.007-1.643-.006-2.465A1.154,1.154,0,0,1,1054.9,1831.549Zm2.812,3.781c-1.031.01-1.009-.1-.894.861a.057.057,0,0,0,.008.031c.081.085.156.232.247.244a2.292,2.292,0,0,0,1.512-.243.543.543,0,0,0,.23-.438.567.567,0,0,0-.357-.347A4.178,4.178,0,0,0,1057.709,1835.33Zm-.077-2.42c-.239.039-.514.074-.783.135a.315.315,0,0,0-.2.169.606.606,0,0,0,.618.844,2.225,2.225,0,0,0,.882-.243.657.657,0,0,0,.26-.518c0-.119-.239-.249-.391-.335A1.16,1.16,0,0,0,1057.632,1832.91Z" transform="translate(-1029.108 -1831.249)"/>
				<path id="Path_22" data-name="Path 22" d="M1539.172,2074.418a17.73,17.73,0,0,1,4.4.543,1.094,1.094,0,0,1,.681.428,4.045,4.045,0,0,1,.707,2.98,2.851,2.851,0,0,1-2.048,2.1,10.287,10.287,0,0,1-3.342.508.384.384,0,0,1-.456-.413c-.015-.46-.028-.92-.024-1.38.014-1.562.035-3.123.055-4.684A.292.292,0,0,1,1539.172,2074.418Zm1.621,3.01h0a3.227,3.227,0,0,0-.026.574,5.851,5.851,0,0,0,.186.9c.054.185.2.238.408.187a2.551,2.551,0,0,0,1.634-1.769.651.651,0,0,0-.257-.679,2.893,2.893,0,0,0-1.642-.548c-.168,0-.224.059-.231.22C1540.851,2076.684,1540.818,2077.056,1540.792,2077.428Z" transform="translate(-1482.254 -2058.508)"/>
				<path id="Path_23" data-name="Path 23" d="M1500.921,1952.261a6.15,6.15,0,0,1,3.278.433,2.612,2.612,0,0,1,1.623,2.25,2.938,2.938,0,0,1-1.012,2.663,4.568,4.568,0,0,1-3.591,1.107c-.364-.013-.727-.045-1.09-.044-.222,0-.313-.092-.321-.3-.054-1.4-.118-2.8-.16-4.2-.016-.534.025-1.07.022-1.6,0-.224.086-.309.3-.306C1500.345,1952.265,1500.719,1952.261,1500.921,1952.261Zm.455,4.058.033,0c0,.117,0,.235,0,.353-.012.31.116.461.445.491a1.942,1.942,0,0,0,2.039-2.457.831.831,0,0,0-.282-.45,2.152,2.152,0,0,0-1.781-.406.278.278,0,0,0-.172.176C1501.554,1954.791,1501.467,1955.555,1501.376,1956.318Z" transform="translate(-1445.339 -1944.158)"/>
				<path id="Path_24" data-name="Path 24" d="M1556.1,1831.754c.083-.818.16-1.592.242-2.4l1.532-.124c-.021.265-.053.5-.057.744-.027,1.69-.047,3.381-.071,5.071-.007.528-.021.541-.546.521a1.977,1.977,0,0,0-.823.065l-2.517-3.454c-.01.178-.017.288-.022.4q-.052,1.152-.1,2.3a1.418,1.418,0,0,0,0,.414c.078.293-.033.365-.3.34-.351-.033-.7-.058-1.056-.06-.24,0-.341-.116-.327-.331a36.662,36.662,0,0,1,.278-4.862,2.176,2.176,0,0,0-.008-.727c-.034-.235.051-.324.266-.321a8.006,8.006,0,0,1,.993.032c.133.018.261.16.369.268.677.682,1.347,1.371,2.021,2.056A.761.761,0,0,0,1556.1,1831.754Z" transform="translate(-1494.371 -1829.083)"/>
				<path id="Path_25" data-name="Path 25" d="M1436.653,2079.278v3.214c-.509-.04-.961-.068-1.412-.114-.22-.022-.257-.185-.245-.377.046-.715.1-1.429.128-2.145q.072-1.682.116-3.365c.007-.247.066-.358.335-.293a1.817,1.817,0,0,0,.541.052,1.643,1.643,0,0,1,1.532.844c.469.654,1.016,1.251,1.567,1.918a14.292,14.292,0,0,0-.2-2.882,3.666,3.666,0,0,1,1.55.1c.049.013.088.156.092.24.038.82.107,1.642.088,2.461-.023,1.012-.119,2.023-.188,3.034a.273.273,0,0,1-.312.285c-.213,0-.428.066-.637.046-.394-.038-.791.005-1.031-.5a11.862,11.862,0,0,0-1.068-1.631c-.229-.326-.493-.627-.741-.94Z" transform="translate(-1384.84 -2060.093)"/>
				<path id="Path_26" data-name="Path 26" d="M1401.362,1953.872c0-.445-.01-.89,0-1.334.014-.48.05-.96.078-1.44,0-.063.017-.178.035-.18a5.155,5.155,0,0,1,.891-.054c.083.006.213.2.219.315.052.95.067,1.9.121,2.853.03.532.133,1.06.161,1.592a12.657,12.657,0,0,1-.034,1.278c-.009.192-.116.276-.321.259-.458-.037-.917-.063-1.376-.084-.256-.012-.358-.182-.464-.38a15,15,0,0,0-.782-1.358,6.069,6.069,0,0,0-.982-1.07c-.019.277-.058.554-.052.831.015.62.059,1.239.079,1.859.012.365-.037.4-.407.391s-.764-.033-1.218-.053a7.075,7.075,0,0,1-.1-.953c.014-.736.074-1.47.1-2.206q.054-1.345.086-2.691c.013-.561.029-.579.571-.589.285-.005.569-.046.8-.066l2.4,3.153Z" transform="translate(-1349.486 -1942.84)"/>
				<path id="Path_27" data-name="Path 27" d="M737.943,1902.335h2.167c.075,0,.149-.009.224-.014.286-.021.436.071.4.4a6.171,6.171,0,0,0-.013.961c.017.3-.12.355-.379.347-.5-.016-1.005,0-1.508-.01-1.238-.031-2.477-.071-3.764-.108-.023-.446-.063-.869-.054-1.29,0-.1.147-.221.25-.3.932-.732,1.865-1.462,2.807-2.181a1.375,1.375,0,0,0,.518-.854.635.635,0,0,0-.7-.8,2.154,2.154,0,0,0-1.468.883l-1.275-1.1a2.841,2.841,0,0,1,3.424-1.447,2.631,2.631,0,0,1,1.608.913,2.421,2.421,0,0,1-.091,2.813,7.037,7.037,0,0,1-1.915,1.636C738.09,1902.235,738,1902.295,737.943,1902.335Z" transform="translate(-729.815 -1892.209)"/>
				<path id="Path_28" data-name="Path 28" d="M1264.972,1837.076c-.626,0-1.2.007-1.774-.011-.057,0-.156-.157-.156-.241,0-.984.008-1.969.031-2.953.019-.813.061-1.625.094-2.437.009-.223-.059-.542.058-.643.136-.117.438-.061.666-.05,1,.05,2.011.065,3.007.181a1.555,1.555,0,0,1,1.406,2.234,6.9,6.9,0,0,1-.688,1.365.565.565,0,0,0-.014.7q.457.658.889,1.335a1.233,1.233,0,0,1,.1.32c-.095.015-.189.033-.284.044-.393.048-.785.113-1.179.13a.6.6,0,0,1-.426-.174c-.373-.441-.714-.907-1.068-1.364-.154-.2-.333-.339-.6-.226s-.264.348-.242.571c.03.3.1.59.142.886C1264.95,1836.867,1264.962,1836.991,1264.972,1837.076Zm-.146-4.214a1.43,1.43,0,0,0,.01.444c.045.13.167.32.266.327a5.486,5.486,0,0,0,1.234-.021.767.767,0,0,0,.549-.776.714.714,0,0,0-.65-.565,6.131,6.131,0,0,0-.732-.064C1264.74,1832.194,1264.857,1832.127,1264.826,1832.862Z" transform="translate(-1223.932 -1830.48)"/>
				<path id="Path_29" data-name="Path 29" d="M1349.022,2082.156l.25.166a1.728,1.728,0,0,0-.22.119.851.851,0,0,0-.126.132,1.319,1.319,0,0,0-.363-.108c-1.228.035-2.456.079-3.683.124a3.878,3.878,0,0,1-.509.036c-.079-.008-.209-.117-.211-.183-.023-.766-.024-1.533-.034-2.3-.015-1.175-.037-2.35-.038-3.524a.4.4,0,0,1,.213-.3,2.124,2.124,0,0,1,.656-.125c1.165-.04,2.33-.1,3.494-.083.686.008.7.064.632.735-.095.923.1.794-.878.8-.449,0-.9-.019-1.348,0a3.2,3.2,0,0,0-.652.132c-.183.047-.339.116-.268.371s.17.418.455.392c.49-.046.978-.111,1.469-.136.273-.013.583-.1.787.206a2.179,2.179,0,0,1,.03,1.3.412.412,0,0,1-.248.118c-.34.04-.684.043-1.021.1a6.384,6.384,0,0,0-.959.234c-.291.095-.336.339-.173.789.1.281.325.17.482.1a4.038,4.038,0,0,1,2.21-.141c.2.028.254.108.206.3C1349.112,2081.663,1349.071,2081.915,1349.022,2082.156Z" transform="translate(-1299.773 -2060.115)"/>
				<path id="Path_30" data-name="Path 30" d="M1053.262,1957.762c-.026-.459-.05-.918-.079-1.377-.016-.252.093-.385.348-.39l1.058-.018c.22,0,.316.079.321.324a4.788,4.788,0,0,0,.12.952,9.2,9.2,0,0,1-.053,3.283,1.117,1.117,0,0,1-.267.6,3.47,3.47,0,0,1-2.1,1.1,3.848,3.848,0,0,1-1.858-.16,2.03,2.03,0,0,1-1.277-1.3,10.213,10.213,0,0,1-.471-3.92,1.285,1.285,0,0,1,.19-.6.567.567,0,0,1,.41-.209,10.464,10.464,0,0,1,1.31.058.371.371,0,0,1,.256.248c.031.488.041.979.025,1.468-.014.427-.064.853-.109,1.278a1.8,1.8,0,0,0,.264,1.132.818.818,0,0,0,1.5.046,2.86,2.86,0,0,0,.428-1.523c.013-.331,0-.662,0-.994Z" transform="translate(-1023.623 -1947.695)"/>
				<path id="Path_31" data-name="Path 31" d="M1058.568,2078.117c.156-.142.246-.212.321-.3.377-.423.755-.846,1.122-1.278a3.358,3.358,0,0,0,.18-.347.3.3,0,0,1,.179-.137c.48-.036.96-.064,1.441-.075a.388.388,0,0,1,.3.122c.036.062-.032.22-.1.3-.547.671-1.1,1.34-1.662,2-.18.209-.253.383-.075.633.265.374.5.77.755,1.15.393.586.8,1.165,1.19,1.75a1.219,1.219,0,0,1,.141.287c.081.237-.036.384-.279.354-.5-.06-1-.114-1.491-.2a.558.558,0,0,1-.276-.221c-.611-.766-1.216-1.536-1.876-2.374-.467.909-.1,1.829-.4,2.641-.445,0-.9.011-1.355,0-.259-.009-.25-.247-.249-.413,0-.694.024-1.387.042-2.081.017-.672.035-1.345.058-2.017.016-.47.052-.94.061-1.41,0-.243.081-.338.342-.329a7.723,7.723,0,0,0,1.023-.05c.227-.022.348.061.375.282.042.338.079.677.123,1.015C1058.487,2077.625,1058.523,2077.829,1058.568,2078.117Z" transform="translate(-1030.594 -2059.995)"/>
				<path id="Path_32" data-name="Path 32" d="M1665.037,1835.321a1.647,1.647,0,0,1-.172.02c-1.443-.019-2.887-.045-4.33-.055-.246,0-.283-.108-.267-.315.043-.565.1-1.131.1-1.7,0-1.24-.035-2.48-.056-3.72-.007-.437-.007-.433.435-.447q1.939-.059,3.877-.123c.489-.015.564.058.48.526-.064.357-.15.711-.248,1.061a.29.29,0,0,1-.219.141c-.765.054-1.53.089-2.295.144-.2.015-.383.105-.367.359s.212.271.4.271c.695,0,1.39,0,2.084-.02.265-.008.377.065.34.34s-.048.532-.073.8c-.022.233-.138.316-.384.307-.7-.024-1.41-.01-2.114-.027-.257-.007-.33.1-.327.339.007.557.073.64.622.633.727-.009,1.455-.024,2.182-.051.262-.01.364.06.34.345C1665.014,1834.531,1665.037,1834.914,1665.037,1835.321Z" transform="translate(-1595.646 -1828.855)"/>
				<path id="Path_33" data-name="Path 33" d="M1466.783,1829.874c.3,0,.6-.009.895,0,.234.009.466.062.7.064.909.009,1.819.013,2.728,0,.273,0,.386.094.39.371,0,.32.044.64.068.96.014.182-.027.249-.25.246-.918-.013-1.837.005-2.755.024a.343.343,0,0,0-.339.443c.015.086.224.189.35.2.715.052,1.431.082,2.147.108.228.008.338.094.317.329-.028.328-.041.657-.08.984-.024.2-.152.271-.364.257-.619-.041-1.239-.063-1.858-.086-.172-.007-.348-.018-.357.245-.016.451.052.612.349.621q1.25.037,2.5.038c.274,0,.378.058.331.367a3.835,3.835,0,0,0,.025.861c.021.277-.062.42-.372.407-1.25-.051-2.5-.088-3.752-.13a2.441,2.441,0,0,0-.32,0c-.406.04-.416.043-.408-.352a5.124,5.124,0,0,1,.076-.762,12.919,12.919,0,0,0,.09-3.166,10.326,10.326,0,0,1,.025-1.081c.01-.3.182-.616-.2-.849Z" transform="translate(-1414.53 -1829.687)"/>
				<path id="Path_34" data-name="Path 34" d="M1647.185,2076.614a.541.541,0,0,0-.02.1c.041.6.042.593.643.621.534.026,1.066.084,1.6.11.225.011.3.111.294.319,0,.321.008.642.022.962.008.188-.067.258-.249.215-.135-.032-.273-.055-.4-.1a4.923,4.923,0,0,0-1.97.05c-.069,0-.15.27-.169.421a.368.368,0,0,0,.312.45,10.457,10.457,0,0,0,1.082.121c.544.03,1.089.042,1.634.047.272,0,.393.1.363.385-.027.244-.027.491-.033.737a.33.33,0,0,1-.378.367c-.812.008-1.624.029-2.436.037-.535,0-1.069-.012-1.6,0-.2,0-.274-.054-.278-.271-.015-.844-.079-1.688-.075-2.532.006-1.121.062-2.241.088-3.362.006-.256.1-.352.367-.338.939.048,1.879.08,2.818.109.341.011.684.007,1.025-.01.184-.009.285.03.292.233.01.267.032.533.056.8s-.063.36-.357.365c-.779.011-1.557.072-2.335.116A2.452,2.452,0,0,0,1647.185,2076.614Z" transform="translate(-1581.846 -2059.026)"/>
				<path id="Path_35" data-name="Path 35" d="M1158.719,2074.663c.028.088.064.218.109.344a11.044,11.044,0,0,0,.451,1.256,22.217,22.217,0,0,1,1.686,4.177c.05.192.075.392.122.642a3.848,3.848,0,0,1-.52.082c-.438.011-.877.015-1.314-.008a.381.381,0,0,1-.25-.221,6.3,6.3,0,0,1-.225-.767.413.413,0,0,0-.5-.366c-.361.042-.724.062-1.086.089a.687.687,0,0,0-.629.47,3.484,3.484,0,0,0-.192.576c-.048.242-.159.34-.412.32-.276-.022-.556,0-.833-.006-.4-.008-.53-.161-.427-.547a5.767,5.767,0,0,1,.349-.96c.6-1.275,1.217-2.542,1.816-3.817a4,4,0,0,0,.277-.813.308.308,0,0,1,.317-.276C1157.863,2074.79,1158.27,2074.726,1158.719,2074.663Zm-.983,2.3a1.812,1.812,0,0,0-.543,1.279.344.344,0,0,0,.229.249,4.581,4.581,0,0,0,.828-.026c.262-.027.342-.169.208-.4C1158.24,2077.709,1158,2077.363,1157.735,2076.967Z" transform="translate(-1122.511 -2058.759)"/>
				<path id="Path_36" data-name="Path 36" d="M1604.864,1951.953c-.212.424-.4.807-.6,1.183-.068.13-.166.179-.307.054a2.929,2.929,0,0,0-1.462-.5.642.642,0,0,0-.39.115c-.238.137-.235.362.01.5a4.01,4.01,0,0,0,.508.193c.638.244,1.277.486,1.913.735a.828.828,0,0,1,.55.72,2.448,2.448,0,0,1-.448,1.785,2,2,0,0,1-2.076.9,4.221,4.221,0,0,1-2.315-.924.334.334,0,0,1-.088-.516c.166-.254.313-.521.465-.776a3.246,3.246,0,0,0,2.227.657c.125,0,.3-.238.376-.4.091-.208-.086-.341-.268-.4-.457-.149-.929-.254-1.377-.424a2.379,2.379,0,0,1-1.532-1.528.976.976,0,0,1,.2-1.02,5.753,5.753,0,0,1,1.062-.968,2.064,2.064,0,0,1,.979-.182A6,6,0,0,1,1604.864,1951.953Z" transform="translate(-1539.244 -1943.172)"/>
				<path id="Path_37" data-name="Path 37" d="M1294.726,1961.51c-.512.026-.964.05-1.417.071-.128.006-.258-.01-.385,0a.239.239,0,0,1-.3-.239c-.027-.19-.067-.379-.109-.566a.538.538,0,0,0-.565-.466c-.47,0-.943-.026-1.41.011a.686.686,0,0,0-.445.287c-.18.274-.289.594-.448.884a.383.383,0,0,1-.249.191c-.393.021-.787.009-1.231.009.067-.212.121-.422.2-.622.572-1.46,1.144-2.92,1.727-4.375.151-.376.333-.739.519-1.1a.478.478,0,0,1,.245-.176,1.173,1.173,0,0,1,.253-.016.975.975,0,0,1,1.19.714c.668,1.594,1.444,3.141,2.172,4.709C1294.563,1961.029,1294.626,1961.241,1294.726,1961.51Zm-3.42-4.21a7.519,7.519,0,0,0-.335.754,5.247,5.247,0,0,0-.126.691c.268.023.535.063.8.065.184,0,.264-.1.191-.3-.106-.29-.187-.588-.3-.877A1.858,1.858,0,0,0,1291.306,1957.3Z" transform="translate(-1247.441 -1947.143)"/>
				<path id="Path_38" data-name="Path 38" d="M841.4,1898.431a3.28,3.28,0,0,1-.706,2.2,1.887,1.887,0,0,1-.826.577,2.767,2.767,0,0,1-2.694-.648,2.03,2.03,0,0,1-.391-.728,3.253,3.253,0,0,1,1.12-3.612,1.312,1.312,0,0,1,1.357-.219,6.936,6.936,0,0,1,1.416.641A1.825,1.825,0,0,1,841.4,1898.431Zm-1.41.071a6.739,6.739,0,0,0-.334-.915.633.633,0,0,0-.881-.248,1.568,1.568,0,0,0,.006,2.5.571.571,0,0,0,.788-.148A2.4,2.4,0,0,0,839.993,1898.5Z" transform="translate(-824.902 -1891.479)"/>
				<path id="Path_39" data-name="Path 39" d="M1209.054,1954.41c.557,0,1.089-.012,1.619.012.079,0,.208.172.215.271.038.563.073,1.13.058,1.694-.016.608-.086,1.214-.135,1.821-.057.7-.058.674.622.709.821.042,1.64.138,2.46.2.21.016.237.116.211.3-.049.348-.085.7-.112,1.049-.013.167-.057.244-.248.245-.767.007-1.535.048-2.3.046-.715,0-1.43-.041-2.144-.068-.266-.01-.219-.219-.22-.38q-.017-1.765-.024-3.53C1209.052,1956.021,1209.054,1955.261,1209.054,1954.41Z" transform="translate(-1173.409 -1946.226)"/>
				<path id="Path_40" data-name="Path 40" d="M1270.623,2082.435c-.549-.036-1.059-.1-1.568-.1-.787.014-1.574.063-2.358.132-.4.035-.475.008-.483-.4-.022-1.111-.035-2.221-.059-3.332-.015-.713-.07-1.426-.056-2.138.011-.529.048-.529.592-.532h.064c.8,0,.862.063.876.839s.035,1.56.059,2.341c.011.385-.165.756-.008,1.158a.544.544,0,0,0,.572.427c.715.014,1.43.07,2.144.124.079.006.212.107.215.17C1270.632,2081.554,1270.623,2081.978,1270.623,2082.435Z" transform="translate(-1226.789 -2060.077)"/>
				<path id="Path_41" data-name="Path 41" d="M762.558,2025.248a4.975,4.975,0,0,1-.048-.717.5.5,0,0,1,.189-.3c.558-.449,1.126-.884,1.687-1.329a.741.741,0,0,0,.21-.237.532.532,0,0,0,.005-.63.451.451,0,0,0-.589-.063,6.8,6.8,0,0,0-.687.434l-.714-.62a1.671,1.671,0,0,1,1.668-.957,4.975,4.975,0,0,1,.625.132,1.38,1.38,0,0,1,.618,2.3c-.381.35-.776.684-1.19,1.048.427-.019.849-.023,1.267-.062.3-.028.443.048.408.374a5.9,5.9,0,0,0,0,.622Z" transform="translate(-755.539 -2008.377)"/>
				<path id="Path_42" data-name="Path 42" d="M1161.068,1996.213c.749,0,1.527-.005,2.305.009.073,0,.147.126.214.2.016.018,0,.062,0,.094.038,1.23.023,1.247-1.178,1.247-.353,0-.706-.006-1.059,0-.214,0-.3-.063-.292-.3C1161.083,1997.053,1161.068,1996.64,1161.068,1996.213Z" transform="translate(-1128.499 -1985.346)"/>
				<path id="Path_43" data-name="Path 43" d="M975.722,1831.436h-1c.04-.2.085-.357.1-.521.03-.259-.033-.433-.36-.4a7.818,7.818,0,0,1-.793.005c-.023-.348-.053-.684-.059-1.019,0-.049.106-.128.176-.147a3.326,3.326,0,0,1,1.674-.089.3.3,0,0,1,.269.354c-.021.394-.034.789-.041,1.184C975.691,1830.983,975.709,1831.162,975.722,1831.436Z" transform="translate(-953.09 -1829.054)"/>
				<path id="Path_44" data-name="Path 44" d="M984.268,2010.624a6.692,6.692,0,0,0-.25-3.094c.423.045.767.068,1.1.127.068.012.152.157.161.248.079.764.148,1.528.2,2.294a.3.3,0,0,1-.169.238C985,2010.514,984.671,2010.556,984.268,2010.624Z" transform="translate(-962.825 -1995.937)"/>
				<path id="Path_45" data-name="Path 45" d="M655.371,2023.235c-.086-.74-.165-1.435-.246-2.13-.03-.257.06-.413.338-.429.074,0,.146-.031.22-.042.829-.126.672-.268.774.648.049.443.081.887.112,1.331.018.256-.039.468-.35.506C655.945,2023.152,655.672,2023.194,655.371,2023.235Z" transform="translate(-655.047 -2008.114)"/>
				<path id="Path_46" data-name="Path 46" d="M986.11,2081.815l1.1.066c.079.753.159,1.535.244,2.317.022.2-.067.293-.262.306-.316.021-.633.045-.947.068Z" transform="translate(-964.783 -2065.451)"/>
				<path id="Path_47" data-name="Path 47" d="M659.4,1834.911l-1.717-.161c-.036.179-.025.442-.108.475a2.854,2.854,0,0,1-.732.1c-.043-.446-.091-.853-.118-1.26-.014-.223.078-.353.343-.339.656.035,1.314.038,1.97.079a.37.37,0,0,1,.263.227A7.77,7.77,0,0,1,659.4,1834.911Z" transform="translate(-656.549 -1833.298)"/>
				<path id="Path_48" data-name="Path 48" d="M658.953,2085.078h-1.107c-.046-.84-.093-1.708-.143-2.62.339.023.633.039.927.065.231.021.328.154.326.387C658.949,2083.612,658.953,2084.313,658.953,2085.078Z" transform="translate(-657.465 -2066.053)"/>
				<path id="Path_49" data-name="Path 49" d="M783.537,2156.113h.713q.781,0,1.563.007c.177,0,.26.086.284.27.107.829.083.851-.748.871-.6.014-1.205.061-1.812.094Z" transform="translate(-775.219 -2134.979)"/>
				<path id="Path_50" data-name="Path 50" d="M658.852,1956.885c-.052-.878-.1-1.729-.153-2.581-.012-.21.092-.319.309-.309.085,0,.171,0,.256,0,.448.009.5.071.529.525.035.628.088,1.255.113,1.884a.325.325,0,0,1-.168.253C659.459,1956.751,659.166,1956.808,658.852,1956.885Z" transform="translate(-658.397 -1945.839)"/>
				<path id="Path_51" data-name="Path 51" d="M847.737,2159.321h-2.495l-.229-1.051h2.831Z" transform="translate(-832.747 -2136.997)"/>
				<path id="Path_52" data-name="Path 52" d="M975.067,2146.807c.307,0,.569-.012.828.01.06,0,.149.125.157.2.052.515.085,1.032.129,1.6-.692-.037-1.287-.063-1.88-.108-.063,0-.145-.111-.172-.186-.057-.16-.081-.331-.124-.5-.069-.268.072-.356.305-.351.138,0,.275.038.414.045.413.023.514-.1.412-.5C975.122,2146.967,975.1,2146.918,975.067,2146.807Z" transform="translate(-953.438 -2126.267)"/>
				<path id="Path_53" data-name="Path 53" d="M986.223,1952.351c-.352,0-.629.016-.9-.01a.3.3,0,0,1-.207-.206c-.062-.749-.1-1.5-.158-2.371.438.1.764.156,1.081.247a.311.311,0,0,1,.178.229C986.229,1950.93,986.223,1951.62,986.223,1952.351Z" transform="translate(-963.703 -1941.88)"/>
				<path id="Path_54" data-name="Path 54" d="M911.926,2156.2c.905-.044,1.791.314,2.736-.12-.071.414-.121.754-.2,1.09-.01.046-.14.092-.215.094-.663.019-1.326.031-1.988.031a.253.253,0,0,1-.183-.156C912.018,2156.845,911.981,2156.548,911.926,2156.2Z" transform="translate(-895.363 -2134.946)"/>
				<path id="Path_55" data-name="Path 55" d="M986.623,1890.128c0-.5.008-.966,0-1.428-.006-.276-.04-.552-.063-.828-.019-.236.087-.312.308-.244.01,0,.021,0,.031.006.777.157.785.156.829.949.023.414.011.83.018,1.245,0,.2-.082.242-.275.239C987.178,1890.063,986.885,1890.107,986.623,1890.128Z" transform="translate(-965.2 -1883.713)"/>
				<path id="Path_56" data-name="Path 56" d="M710.266,2161.188c-.035-.281-.073-.564-.105-.848-.024-.217.138-.209.276-.209.725,0,1.45,0,2.266,0-.021.264-.034.542-.072.816a.237.237,0,0,1-.17.147c-.68.049-1.362.085-2.043.123A.785.785,0,0,1,710.266,2161.188Z" transform="translate(-706.552 -2138.736)"/>
				<path id="Path_57" data-name="Path 57" d="M910.658,1833.133a4.607,4.607,0,0,1,.186-.9c.041-.1.3-.138.471-.156.571-.061,1.144-.1,1.717-.146.056,0,.141,0,.168.035a.655.655,0,0,1,.009.721c-.083.192-.146.356-.421.341-.5-.028-1-.02-1.5-.01A4.626,4.626,0,0,0,910.658,1833.133Z" transform="translate(-894.176 -1831.614)"/>
				<path id="Path_58" data-name="Path 58" d="M846.193,1832.556l-.1,1.057a5.126,5.126,0,0,0-2.288.094c-.158-.32.032-.657.054-.994,0-.026.125-.061.192-.064C844.733,1832.616,845.413,1832.589,846.193,1832.556Z" transform="translate(-831.56 -1832.199)"/>
				<path id="Path_59" data-name="Path 59" d="M662.727,2152.412h-1.505v-1.536c.269,0,.582-.01.893.009.061,0,.139.111.166.185a1.235,1.235,0,0,1,.032.307c.565.171.566.171.477.768C662.775,2152.237,662.747,2152.327,662.727,2152.412Z" transform="translate(-660.757 -2130.075)"/>
				<path id="Path_60" data-name="Path 60" d="M783.64,1832.638l.161,1.068c-.4-.027-.735-.058-1.072-.07a6.007,6.007,0,0,0-.958.037.945.945,0,0,1-.08-.906.376.376,0,0,1,.234-.062c.309,0,.619.021.928.012C783.09,1832.711,783.325,1832.671,783.64,1832.638Z" transform="translate(-773.414 -1832.276)"/>
				<path id="Path_61" data-name="Path 61" d="M711.552,1833.857l-.238-1.239c.539-.077,1.042-.153,1.547-.214a.225.225,0,0,1,.172.125,1.91,1.91,0,0,1,.088.436c.053.534-.014.608-.543.647a3.939,3.939,0,0,0-.541.054A3.451,3.451,0,0,0,711.552,1833.857Z" transform="translate(-707.633 -1832.057)"/>
			</g>
			<path id="Path_62" data-name="Path 62" d="M833.908,1986l-1.471,1.471a.154.154,0,0,0,.109.263h1.361v4.856a.54.54,0,0,0,.653.529l1.038-.22a.54.54,0,0,0,.428-.528v-6.048a.321.321,0,0,0-.321-.321Z" transform="translate(-820.936 -1975.79)"/>
		</g>
	</svg>
);
