import React, { useRef } from 'react';
import clsx from 'clsx';
import { CloseButton } from './svgs';
import useOnClickOutside from '../hooks/useOnClickOutside';

const VimeoModal = ({ close, theme = 'themeTransport', video, title }) => {
	const modalRef = useRef(null);
	useOnClickOutside(modalRef, close);

	return <div className="modal-container faded">
		<div ref={modalRef} className={clsx('modal', theme)}>
			<div onClick={close} className="close">
				<CloseButton />
			</div>
			<div className="overflow-hidden rounded-3xl video-aspect">
				<iframe
					src={`https://player.vimeo.com/video/${video}`}
					frameBorder="0"
					allow="autoplay; fullscreen"
					allowFullScreen
					title={title}
					className="w-full h-full -mx-4"
				/>
			</div>
		</div>
	</div>;
};

export default VimeoModal;
