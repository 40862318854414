import React from 'react';

export const MapIcon = ({ ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.235 27.513" {...rest}>
		<g id="Group_296" data-name="Group 296" transform="translate(1 .5)" strokeMiterlimit="10">
			<g id="Group_295" data-name="Group 295" transform="translate(0 9.766)" fill="#fff" strokeLinecap="round">
				<g id="Path_4844" data-name="Path 4844" strokeLinejoin="round">
					<path d="m1461.15 203.633 5.264 2.451h7.261l3.631 2.178 8.078-11.618-3.631-2.178h-7.261l-5.264-2.451Z" transform="translate(-1461.15 -192.015)"/>
					<path d="m1477.306 208.262 8.079-11.618-3.631-2.179h-7.262l-5.264-2.45-8.078 11.618 5.264 2.45h7.261l3.631 2.179m0 1a.997.997 0 0 1-.514-.143l-3.394-2.036h-6.984a1 1 0 0 1-.422-.093l-5.264-2.45a1 1 0 0 1-.4-1.478l8.079-11.618a1 1 0 0 1 1.243-.336l5.064 2.357h7.04a1 1 0 0 1 .514.143l3.631 2.178a1 1 0 0 1 .307 1.429l-8.079 11.618a1 1 0 0 1-.822.429Z" fill="#000" transform="translate(-1461.15 -192.015)"/>
				</g>
				<path id="Line_546" data-name="Line 546" stroke="#000" strokeWidth="1" d="m5.264 14.068 6.853-9.984"/>
				<path id="Line_547" data-name="Line 547" stroke="#000" strokeWidth="1" d="m20.604 2.451-6.807 9.803"/>
			</g>
			<path id="Path_4008" data-name="Path 4008" d="M14.352-.004a5.893 5.893 0 0 0-1.8 11.505l.161.28 1.386 2.4a.287.287 0 0 0 .5 0l1.386-2.4.161-.28a5.893 5.893 0 0 0-1.8-11.505Zm0 4.278a1.218 1.218 0 1 1 1.218-1.218 1.218 1.218 0 0 1-1.218 1.222Z" fill="#fff" stroke="#000" strokeWidth="1"/>
		</g>
	</svg>
);
