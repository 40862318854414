import React from 'react';

export const OleKirkLogo = ({ fill = '#fff', ...rest }) => (
	<svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 88.999 11.23">
		<g id="Group_292" data-name="Group 292" transform="translate(-33.69 -18.509)">
			<g id="Group_291" data-name="Group 291" transform="translate(33.69 19.737)">
				<path id="Path_4826" data-name="Path 4826" d="M33.69,36.377v-.1a4.586,4.586,0,0,1,4.774-4.722,4.509,4.509,0,0,1,4.774,4.67v.1A4.559,4.559,0,0,1,38.451,41.1,4.518,4.518,0,0,1,33.69,36.377Zm7.356-.026v-.1c0-1.8-.96-3.036-2.582-3.036s-2.582,1.181-2.582,3.049v.1c0,1.894,1.09,3.062,2.608,3.062C40.06,39.426,41.046,38.219,41.046,36.351Z" transform="translate(-33.69 -31.097)" fill={fill}/>
				<path id="Path_4827" data-name="Path 4827" d="M115.379,28.03h1.868v9.86h-1.868Z" transform="translate(-104.843 -28.03)" fill={fill}/>
				<path id="Path_4828" data-name="Path 4828" d="M138.72,54.295v-.1a3.368,3.368,0,0,1,3.49-3.529,3.128,3.128,0,0,1,3.308,3.451v.519h-4.891a1.626,1.626,0,0,0,1.686,1.777c.869,0,1.3-.376,1.414-.947h1.777c-.221,1.466-1.388,2.283-3.243,2.283A3.292,3.292,0,0,1,138.72,54.295Zm4.969-.817c-.065-1.025-.584-1.518-1.479-1.518a1.557,1.557,0,0,0-1.557,1.518Z" transform="translate(-125.173 -47.743)" fill={fill}/>
				<path id="Path_4829" data-name="Path 4829" d="M224.211,32.469h2.1v4l3.243-4.009h2.231l-3.671,4.4,3.853,4.891h-2.452l-3.2-4.164v4.164h-2.1Z" transform="translate(-199.638 -31.885)" fill={fill}/>
				<path id="Path_4830" data-name="Path 4830" d="M293.6,51.869h1.868v6.785H293.6Z" transform="translate(-260.077 -48.794)" fill={fill}/>
				<path id="Path_4831" data-name="Path 4831" d="M320.259,51.168h1.881v1.3a2.228,2.228,0,0,1,2.193-1.4v1.752c-1.388-.013-2.193.441-2.193,1.738v3.4h-1.881Z" transform="translate(-283.298 -48.093)" fill={fill}/>
				<path id="Path_4832" data-name="Path 4832" d="M359.2,28.03h1.881v5.942l2.18-2.867h2l-2.465,3.088,2.66,3.7H363.32l-2.244-3.2v3.2H359.2Z" transform="translate(-317.212 -28.03)" fill={fill}/>
				<path id="Path_4833" data-name="Path 4833" d="M412.8,35.553l1.071-3h1.8l-1.809,3.4Z" transform="translate(-363.902 -31.972)" fill={fill}/>
				<path id="Path_4834" data-name="Path 4834" d="M427.2,55.475h1.751c.078.61.376.96,1.194.96.727,0,1.064-.272,1.064-.739s-.4-.662-1.375-.8c-1.8-.272-2.5-.791-2.5-2.115,0-1.414,1.3-2.115,2.685-2.115,1.5,0,2.634.545,2.8,2.1H431.09a.919.919,0,0,0-1.064-.817c-.61,0-.96.285-.96.7s.324.6,1.31.739c1.7.247,2.634.675,2.634,2.128s-1.064,2.231-2.854,2.231C428.327,57.745,427.263,56.941,427.2,55.475Z" transform="translate(-376.444 -47.743)" fill={fill}/>
				<path id="Path_4835" data-name="Path 4835" d="M502.745,32.556H508.7V34.2h-3.853v2.452H507.9v1.583h-3.049v3.594h-2.1Z" transform="translate(-442.247 -31.972)" fill={fill}/>
				<path id="Path_4836" data-name="Path 4836" d="M550.022,54.282v-.1a3.4,3.4,0,0,1,3.581-3.516,3.368,3.368,0,0,1,3.568,3.477v.1a3.575,3.575,0,0,1-7.148.039Zm5.241-.026v-.091c0-1.3-.61-2.076-1.661-2.076s-1.661.752-1.661,2.05v.1c0,1.3.6,2.076,1.661,2.076S555.263,55.54,555.263,54.256Z" transform="translate(-483.427 -47.743)" fill={fill}/>
				<path id="Path_4837" data-name="Path 4837" d="M612.986,50.818h1.881v1.077a2.344,2.344,0,0,1,2.206-1.233c1.336,0,2.283.8,2.283,2.621V57.6h-1.881V53.555c0-.921-.363-1.362-1.194-1.362a1.344,1.344,0,0,0-1.414,1.492V57.6h-1.881Z" transform="translate(-538.27 -47.743)" fill={fill}/>
				<path id="Path_4838" data-name="Path 4838" d="M669.512,34.595v-.1c0-2.231,1.284-3.542,2.984-3.542a2.259,2.259,0,0,1,2.141,1.168V28.03H676.5v9.86h-1.868V36.761a2.469,2.469,0,0,1-2.18,1.271C670.809,38.032,669.512,36.852,669.512,34.595Zm5.176-.065v-.1c0-1.349-.6-2.037-1.622-2.037-1.051,0-1.635.714-1.635,2.063v.1c0,1.349.649,2.011,1.583,2.011C674,36.567,674.688,35.9,674.688,34.53Z" transform="translate(-587.505 -28.03)" fill={fill}/>
			</g>
			<path id="Path_4839" data-name="Path 4839" d="M289.6,18.783h0a.936.936,0,0,0-1.323,0l-.309.309-.309-.309a.936.936,0,1,0-1.323,1.323l.309.309,1.323,1.323,1.323-1.323.309-.309A.936.936,0,0,0,289.6,18.783Z" transform="translate(-219.82)" fill={fill}/>
		</g>
	</svg>
);
