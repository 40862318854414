import React, { useState } from 'react';
import VimeoModal from './vimeoModal';
import clsx from 'clsx';
export const LiveShowSection = ({ preview_image, video = '', title, date, body, theme }) => {
	const [isOpen, setOpen ] = useState(false);
	const close = () => setOpen(false);
	const open = () => setOpen(true);

	return <div className="py-124">
		<div className="grid w-full grid-cols-12 mx-auto max-w-grid">
			<div className={clsx('col-span-6', {'cursor-pointer': video !== ''})}>
				<img src={preview_image?.filename || null} className="w-full rounded-3xl" onClick={video !== '' ? open : null}/>
			</div>
			{isOpen && <VimeoModal close={close} video={video} title={title} theme={theme}/>}
			<div className="flex flex-col justify-center col-span-4 col-start-8 text-primary">
				<p className="font-bold font-playFair text-20">{date}</p>
				<p className="mb-48 font-nove text-30">{title}</p>
				<p className="mb-24 font-playFair text-20">{body}</p>
				{video !== '' && <span onClick={open} className="self-start inline-block px-24 text-black rounded-full shadow-sm cursor-pointer h-34 leading-34 font-nove bg-white-100">Afspil</span>}
			</div>
		</div>
	</div>;
};
