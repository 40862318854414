import React, { useRef, useEffect, useState } from 'react';
import { Text } from './typography';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { richTextResolver } from '../utils/richTextResolver';

export const ImageLeft = ({ blok }) => {
	const containerRef = useRef();
	const textBlockRef = useRef();
	const imageBlockRef = useRef();
	const [bodyHtml, setBodyHtml ] = useState(null);

	gsap.registerPlugin(ScrollTrigger);

	const initAnimations = () => {
		gsap.fromTo(textBlockRef.current, 
			{ 
				xPercent: 50, 
			}, 
			{
				xPercent: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'center center',
					scrub: true,
				},
			});

		gsap.fromTo(imageBlockRef.current, 
			{ 
				xPercent: -50, 
			}, {
				xPercent: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'center center',
					scrub: true,
				},
			});
	};

	useEffect(() => {
		const html = richTextResolver(blok.body);
		setBodyHtml(html);

		return () => {
			gsap.killTweensOf(textBlockRef.current);
			gsap.killTweensOf(imageBlockRef.current);
		};
	}, []);

	return  (
		<div ref={containerRef} className="grid grid-cols-12 mx-auto my-124 max-w-grid w-92p">
			<div ref={imageBlockRef} className="col-start-1 col-end-7 overflow-hidden rounded-3xl">
				{blok.image.filename !== null && <img src={blok.image.filename} onLoad={initAnimations} width="100%"/>}
			</div>
			<div ref={textBlockRef} className="flex flex-col justify-center col-start-8 col-end-12 text-primary">
				<Text type="heading2" className="mb-36">{blok.heading}</Text>
				<Text type="body1HTML" html={bodyHtml} />
			</div>
		</div>
	);
};

export const ImageRight = ({ blok }) => {
	const containerRef = useRef();
	const textBlockRef = useRef();
	const imageBlockRef = useRef();
	const [bodyHtml, setBodyHtml ] = useState(null);

	gsap.registerPlugin(ScrollTrigger);

	const initAnimations = () => {
		gsap.fromTo(textBlockRef.current, 
			{ 
				xPercent: -50, 
			}, 
			{
				xPercent: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'center center',
					scrub: true,
				},
			});

		gsap.fromTo(imageBlockRef.current, 
			{ 
				xPercent: 50, 
			}, {
				xPercent: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end: 'center center',
					scrub: true,
				},
			});
	};

	useEffect(() => {
		const html = richTextResolver(blok.body);
		setBodyHtml(html);

		return () => {
			gsap.killTweensOf(textBlockRef.current);
			gsap.killTweensOf(imageBlockRef.current);
		};
	}, []);

	return (
		<div ref={containerRef} className="grid grid-cols-12 mx-auto my-124 max-w-grid w-92p">
			<div ref={textBlockRef} className="flex flex-col justify-center col-start-1 col-end-5 text-primary">
				<Text type="heading2" className="mb-36">{blok.heading}</Text>
				<Text type="body1HTML" html={bodyHtml} />
			</div>
			<div ref={imageBlockRef} className="col-start-7 col-end-13 overflow-hidden rounded-3xl">
				{blok.image.filename !== null && <img src={blok.image.filename} onLoad={initAnimations} width="100%"/>}
			</div>
		</div>
	);
};
