import React from 'react';

export const CompasNeedle = ({ ...rest }) => (
	<svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 516.6 492.9">
		<path fill="#4f74f0" strokeMiterlimit={10} strokeWidth={5} stroke="#fff" d="M224.65,241.78l19-80,16.8-68.59a2.7,2.7,0,0,1,5.25.06l15,69,22.53,79.49"/>
		<path fill="#f8ba7b" strokeMiterlimit={10} strokeWidth={5} stroke="#fff" d="M303.26,242,284.2,322l-16.79,68.6a2.7,2.7,0,0,1-5.25-.07l-15-69L224.65,242"/>
		<path fill="#4f74f0" strokeMiterlimit={10} strokeWidth={5} stroke="#fff" d="M303.26,241.25a39.31,39.31,0,1,1-78.61,0"/>
		<path fill="#f8ba7b" strokeMiterlimit={10} strokeWidth={5} stroke="#fff" d="M224.65,241.25a39.31,39.31,0,0,1,78.61,0"/>
	</svg>
);
