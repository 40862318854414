import React from 'react';
import clsx from 'clsx';

const Animated = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'text-secondary text-36 font-nove'])} />;
const AnimatedSmall = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'text-secondary text-24 font-nove'])} />;
const AnswerIndicator = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'font-bold text-primary font-15 font-playFair'])} />;
const Body1 = ({ className, ...rest }) => <p {...rest} className={clsx([className, 'text-20 font-playFair'])} />;
const Body1HTML = ({ className, html, ...rest }) => <div {...rest} dangerouslySetInnerHTML={{__html: html}} className={clsx([className, 'content-editor text-20 font-playFair'])} />;
const Body2 = ({ className, ...rest }) => <p {...rest} className={clsx([className, 'text-17 font-playFair'])} />;
const Country = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'text-15 font-nove'])} />;
const Default = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'font-bold text-default'])}/>;
const Heading1 = ({ className, ...rest}) => <h2 {...rest} className={clsx([className, 'text-35 font-nove'])} />;
const Heading2 = ({ className, ...rest}) => <h3 {...rest} className={clsx([className, 'text-30 font-nove'])} />;
const NavBack = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'text-14 font-nove'])} />;
const Rangetext = ({ className, ...rest }) => <span {...rest} className={clsx([className, 'text-20 font-nove'])} />;
const RangeNumber = ({ className, ...rest }) => <span  {...rest} className={clsx([className, 'text-17 font-nove'])} />;
const SideMenutitle = ({ className, ...rest }) => <h4 {...rest} className={clsx([className, 'text-12 font-bold font-playFair'])} />;
const SubHeadning1 = ({ className, ...rest}) => <span {...rest} className={clsx([className, 'text-16 font-bold font-playFair'])} />;
const SubHeadning2 = ({ className, ...rest}) => <span {...rest} className={clsx([className, 'text-27 font-nove'])} />;
const NoveXLarge = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'text-48 font-nove text-white-100')} />;
const NoveLarge = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'text-25 font-nove text-white-100')} />;
const NoveMedium = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'text-20 font-nove text-white-100')} />;
const PlayFairLarge = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'font-bold text-25 text-white-100 font-playFair')} />;
const PlayFairMedium = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'font-bold text-20 font-playFair')} />;
const PlayFairSmall = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'font-bold text-15 font-playFair')} />;
const StatText = ({ className, ...rest}) => <p {...rest} className={clsx(className, 'inline-block text-white-100 font-playFair text-17')} />;

const variants = {
	animated: Animated,
	animatedSmall: AnimatedSmall,
	'answer_indicator': AnswerIndicator,
	body1: Body1,
	body1HTML: Body1HTML,
	body2: Body2,
	country: Country,
	default: Default,
	heading1: Heading1,
	heading2: Heading2,
	navBack: NavBack,
	noveLarge: NoveLarge,
	noveMedium: NoveMedium,
	noveXLarge: NoveXLarge,
	rangeNumber: RangeNumber,
	rangeText: Rangetext,
	sidemenuTitle: SideMenutitle,
	subheading1: SubHeadning1,
	subheading2: SubHeadning2,
	pfLarge: PlayFairLarge,
	pfMedium: PlayFairMedium,
	pfSmall: PlayFairSmall,
	statText: StatText,
};

export const Text = (props) => {
	const { type = 'default'} = props;
	const TextComponent = variants[type] || variants.default;
	return <TextComponent {...props} />;
};
