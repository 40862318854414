import React from 'react';

export const AnimationCurve1 = ({ offset = 0, text}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="443" height="183" viewBox="0 0 443 183" className="overflow-visible pointer-events-none bottom-20">
		<path id="TextAnimation_path1" fill="none" stroke="#ffffff00" d="M5.77,167.41c0,0,279.78,65.05,430.68-159.52"/>
		<text fontSize="72px" fill="#F8BA7B">
			<textPath xlinkHref="#TextAnimation_path1" startOffset={offset}>{text}</textPath>
		</text>
	</svg>
);
