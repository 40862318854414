import React, { useEffect, useRef, useContext, useState } from 'react';
import { gsap } from 'gsap';
import { Text } from './typography';
import { AnswerIndicator } from './answerIndicator';
import { Face } from './face';
import { ScrollDownArch } from './svgs';
import GlobalContext from '../context/global-context';
import nf from '../utils/numberFormatter';
import useLocalStorage from './../hooks/useLocalstorage';
import { popOut } from '../utils/customEase';

export const AnswerResultsSection = ({ blok, fullSlug }) => {
	const [ storedValue ] = useLocalStorage('FKN_Questions');
	const [ answers, setAnswers ] = useState(null);
	const context = useContext(GlobalContext);
	const denmarkHasAnswered = useRef();
	const klasseHasAnswered = useRef();

	useEffect(() => {
		setAnswers(storedValue[fullSlug]);
		gsap.fromTo(denmarkHasAnswered.current, {
			opacity: 0,
			scale: .3,
		},{
			opacity: 1,
			scale: 1,
			delay: 6,
			duration: .6,
			ease: popOut,
		});

		if(klasseHasAnswered?.current) {
			gsap.fromTo(klasseHasAnswered.current, {
				opacity: 0,
				scale: .3,
			},{
				opacity: 1,
				scale: 1,
				delay: 7.3,
				duration: .6,
				ease: popOut,
			});
		}
	}, []);
	
	return (<>
		<section className="relative flex items-center min-h-screen pb-36 bg-secondary pt-header ">
			<div className="grid grid-cols-12 mx-auto w-92p max-w-grid">
				<Text type="heading2" className="col-span-2 col-start-1 text-primary mb-36" >{context.tr('yourAnswer')}</Text>
				<div className="col-start-1 col-end-8 pb-87">
					{answers && <>
						<AnswerIndicator
							title={blok.Denmark_title}
							user_answer={answers.denmark}
							answer={blok.Denmark_answer}
							answer_max={answers.denmark_max}
							units={blok.Denmark_units}
						/>

						<AnswerIndicator
							title={blok.Kenya_title}
							user_answer={answers.kenya}
							answer={blok.Kenya_answer}
							answer_max={answers.kenya_max}
							units={blok.Kenya_units}
						/>
					</>}
				</div>
				<div className="col-start-9 col-end-13 px-24">
					<Face width="100%"/>
				</div>
				<div ref={denmarkHasAnswered} className="col-start-1 col-end-5">
					<Text type="noveLarge" className="mb-20">{context.tr('denmarkHasAnswered')}</Text>
					<div className="flex items-center w-full mb-20">
						<Text type="body1" className="text-white-100">{blok.Denmark_title}</Text>
						<Text type="noveMedium" className="ml-auto text-white-100 whitespace-nowrap">{`${nf(Math.round(answers?.slider_answer_dk_all))} ${blok.Denmark_units || ''}`}</Text>
					</div>
					<div className="flex items-center w-full">
						<Text type="body1" className=" text-white-100">{blok.Kenya_title}</Text>
						<Text type="noveMedium" className="ml-auto text-white-100 whitespace-nowrap">{`${nf(Math.round(answers?.slider_answer_ke_all))} ${blok.Kenya_units || ''}`}</Text>
					</div>
				</div>
				<div ref={klasseHasAnswered} className="col-start-6 col-end-10">
					{answers?.slider_answer_dk_grade && answers?.slider_answer_ke_grade ? 
						<>
							<Text type="noveLarge" className="mb-20">{context.tr('otherSchoolsHasAnswered')}</Text>
							<div className="flex items-center w-full mb-20">
								<Text type="body1" className="text-white-100">{blok.Denmark_title}</Text>
								<Text type="noveMedium" className="ml-auto text-white-100 whitespace-nowrap">{`${answers?.slider_answer_dk_grade} ${blok.Denmark_units || ''}`}</Text>
							</div>
							<div className="flex items-center w-full">
								<Text type="body1" className="text-white-100">{blok.Kenya_title}</Text>
								<Text type="noveMedium" className="ml-auto text-white-100 whitespace-nowrap">{`${answers?.slider_answer_ke_grade} ${blok.Denmark_units || ''}`}</Text>
							</div>
						</>
						: null}
				</div>
				<ScrollDownArch className="col-start-11 col-end-13 mt-auto overflow-visible"/>
			</div>
		</section>
	</>);
};
