import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

const CtaButton = (props) => {
	const { className, children, ...rest } = props;
	console.warn('CtaButton is deprecated');
	return (
		<Link
			{...rest}
			className={clsx(['inline-block px-24 py-16 text-white-100 rounded-full shadow-lg cursor-pointer font-nove text-17 bg-secondary hover:bg-primary-700', className])}
		>
			{ children }
		</Link>
	);
};

const ContinueButton = (props) => {
	const { className, children, ...rest }  = props;
	console.warn('CtaButton is deprecated');

	return (
		<Link
			{...rest}
			className={clsx(['px-24 py-5 rounded-full inline-block font-nove shadow-sm bg-white-100 text-17 cursor-pointer', className])}
		>
			{ children }
		</Link>
	);
};

const DefaultButton = (props) =>  {
	const { className, children, ...rest } = props;
	console.warn('DefaultButton is deprecated');
	
	return (
		<button
			{...rest}
			className={clsx(['px-24 py-16 text-black bg-white-100 h-34 inline-block rounded-full shadow-sm cursor-pointer text-17', className])}
		>
			{ children }
		</button>
	);
};


const BasicButton = (props) =>  {
	const { className, children, ...rest } = props;
	console.warn('BasicButton is deprecated');
	return (
		<button
			{...rest}
			className={clsx([className, 'basic inline-block max-h-36 font-nove rounded-full shadow-primary shadow-sm cursor-pointer text-17'])}
		>
			{ children }
		</button>
	);
};

// color scheme definition bw: black white, ws: white secondary
const Color_scheme = {
	bw: 'text-black bg-white-100',
	ws: 'text-white-100 bg-secondary',
	wp: 'text-white-100 bg-primary',
};

const LinkButton = ({ className, children, colors = 'bw', ...rest }) => (
	<Link 
		{...rest}
		className={clsx(
			className,
			`px-24 py-5 rounded-full inline-block h-34 font-nove shadow-sm ${Color_scheme[colors]} text-17 cursor-pointer`,
		)}
	>
		{children}
	</Link>
);

const LinkButtonLarge = ({ className, children, colors = 'bw', ...rest }) => (
	<Link 
		{...rest}
		className={clsx(
			className,
			`px-24 rounded-full inline-block h-57 leading-57 font-nove shadow-sm ${Color_scheme[colors]} text-17 cursor-pointer`,
		)}
	>
		{children}
	</Link>
);

const LinkGhostButton = ({ className, children, ...rest }) => (
	<div 
		{...rest}
		className={clsx(
			className,
			'ghostbutton px-24 rounded-full inline-block h-36 w-128 text-center leading-300 font-nove text-white-100 border-1 border-white-100 text-12 cursor-pointer',
		)}
	>
		{children}
	</div>
);

const ExternalLinkButton = ({ className, children, colors = 'bw', ...rest }) => (
	<a 
		{...rest}
		className={clsx(
			className,
			`px-24 py-5 rounded-full inline-block h-34 font-nove shadow-sm ${Color_scheme[colors]} text-17 cursor-pointer`,
		)}
	>
		{children}
	</a>
);

const SubmitButton = ({ className, children, colors = 'ws', ...rest }) => (
	<button
		type="submit"
		{...rest}
		className={clsx(
			className,
			`px-24 py-5 rounded-full inline-block h-34 font-nove shadow-sm ${Color_scheme[colors]} text-17 cursor-pointer hover:bg-primary hover:text-white-100 disabled:opacity-50`,
		)}
	>
		{children}
	</button>
);

const SubmitButtonLarge = ({ className, children, colors = 'ws', ...rest }) => (
	<button
		{...rest}
		className={clsx(
			className,
			`px-24 py-5 rounded-full inline-block h-57 font-nove shadow-sm ${Color_scheme[colors]} text-17 cursor-pointer disabled:opacity-50`,
		)}
	>
		{children}
	</button>
);

const ButtonVariants = {
	cta: CtaButton,
	continue: ContinueButton,
	default: DefaultButton,
	basic: BasicButton,
	linkButton: LinkButton,
	linkButtonLarge: LinkButtonLarge,
	linkGhostButton: LinkGhostButton,
	externalLinkButton: ExternalLinkButton,
	submitButton: SubmitButton,
	submitButtonLarge: SubmitButtonLarge,
};

export const Button = (props) => {
	const { variant = 'default' } = props;
	const ButtonComponent = ButtonVariants[variant] || ButtonVariants['default'];
	return <ButtonComponent {...props} />;
};
