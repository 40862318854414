import React, { useRef, useEffect, useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby';
import gsap from 'gsap';

import useLocalStorage from './../hooks/useLocalstorage';
import { Button, Card, Text } from './';
import { AnimationCurve2, CloudLg, CloudSm ,DenmarkFlag,KenyaFlag,KenyaMapIcon, Sun } from './svgs';
import GlobalContext from '../context/global-context';
import { post } from '../services/api';

export const QuestionSection = ({ blok, storyId }) => {
	const context = useContext(GlobalContext);
	const sunRef = useRef(null);
	const cloud1Ref =  useRef(null);
	const cloud2Ref =  useRef(null);

	const [storedValue, setStoredValue] = useLocalStorage('FKN_Questions', {});
	const [storedSKTCode ] = useLocalStorage('FKN_SKT', '');
	const [offset, setOffset] = useState(0);
	const animatedTextRef = useRef(null);

	const handleSubmit = async (values) => {

		const form = {
			'question_id': storyId,
			'question_name': blok.title,
			'choice_answer_dk': '',
			'choice_answer_ke': '',
			'slider_answer_dk': values.denmark,
			'slider_answer_ke': values.kenya,
			'skt_kode': storedSKTCode, //TODO
		};
		const responseData = await post('svar', form );
		
		setStoredValue({
			...storedValue,
			[blok.answer_page_link.cached_url]: { 
				...values,
				...responseData,
				denmark_max: blok.Denmark_max,
				kenya_max: blok.Kenya_max,
			},
		});

		navigate(`/${blok.answer_page_link.cached_url}`);
	};

	useEffect(() => {
		if(sunRef) {
			gsap.to(sunRef.current, {
				rotation: '-=360',
				duration: 40,
				ease: 'none',
				repeat: -1,
			});

			return () => gsap.killTweensOf(sunRef.current);
		}
	}, []);

	useEffect(() => {
		const o = { offset: offset };
		const endOffset = 700;
		const offsetAnimation = gsap.to(o, {
			offset: endOffset,
			duration: 5,
			onUpdate: () => setOffset(o.offset),
		});
		gsap.to(animatedTextRef.current, {
			opacity:0,
			duration: 5,
		});

		return () => {
			gsap.killTweensOf(animatedTextRef.current);
			offsetAnimation.kill();
		};
	}, []);

	useEffect(() => {
		if(cloud1Ref) {
			const tl1 = gsap.timeline();
			tl1
				.to(cloud1Ref.current, {
					opacity: 1,
					duration: 3,
					ease: 'none',
				})
				.to(
					cloud1Ref.current, {
						opacity: 0,
						duration: 3,
						delay: 13,
						ease: 'none',
						clearProps: true,
					},
				)
				.to(
					cloud1Ref.current,
					{
						right: '65%',
						duration: 19,
						ease: 'none',
					}, 0,
				)
				.repeat(-1);
		}

		if(cloud2Ref) {
			const tl2 = gsap.timeline({ clearProps: true });
			tl2
				.to(cloud2Ref.current, {
					opacity: 1,
					duration: 4,
					ease: 'none',
				})
				.to(
					cloud2Ref.current, {
						opacity: 0,
						duration: 4,
						delay: 11,
						ease: 'none',
					},
				)
				.to(cloud2Ref.current, {
					right: '63%',
					duration: 19,
					ease: 'none',
				}, 0)
				.repeat(-1);
		}

		return () => {
			gsap.killTweensOf(cloud1Ref.current);
			gsap.killTweensOf(cloud2Ref.current);
		};
	}, []);

	return (
		<Formik
			initialValues={{
				denmark: 0,
				kenya: 0,
			}}
			onSubmit={values => handleSubmit(values)}
		>
			<Form>
				<section className="flex items-center justify-center min-h-screen question-section">
					<div className="relative grid grid-cols-12 gap-16 mx-auto max-w-grid w-92p">
						<div className="flex flex-col justify-center col-start-1 col-end-5 text-primary">
							<Text type="subheading1" className="mb-40">{blok.sub_heading}</Text>
							<Text type="heading1" className="mb-32">{blok.title}</Text>
							<Text type="body1">{blok.body}</Text>
						</div>
						<div className="col-start-5 col-end-9 my-auto question-section__card-wrapper">
							<Card
								name="denmark"
								preText={blok.Denmark_pre_answer_text}
								units={blok.Denmark_units}
								image={blok?.Denmark_image}
								min={0}
								max={blok.Denmark_max}
								className="question-section__card"
							/>
							<div className="question-section__flag-wrapper">
								<DenmarkFlag className="question-section__flag" />
								<span className="question-section__flag-caption">{context.tr('denmark')}</span>
							</div>
						</div>
						<div className="col-start-9 col-end-13 my-auto question-section__card-wrapper">
							<Card
								name="kenya"
								preText={blok.Kenya_pre_answer_text}
								units={blok.Kenya_units}
								image={blok?.Kenya_image}
								min={0}
								max={blok.Kenya_max}
								className="question-section__card"
							/>
							<div className="question-section__flag-wrapper">
								<KenyaFlag className="question-section__flag" />
								<span className="question-section__flag-caption">{context.tr('kenya')}</span>
							</div>
						</div>
						<div className='question-section__animated-text w-66p' ref={animatedTextRef}><AnimationCurve2 text={context.tr('guessLoose')} offset={offset}/></div>
						<div className="absolute opacity-0 -top-16 w-260 h-124 right-5p" ref={cloud1Ref}>
							<CloudSm className="w-full"/>
						</div>
						<div className="absolute opacity-0 w-128 h-124 right-5p top-24" ref={cloud2Ref}>
							<CloudLg className="w-full"/>
						</div>
						<div ref={sunRef} className="absolute origin-center pointer-events-none sun -top-32 -right-48" width="130px" >
							<Sun />
						</div>
						<div className="flex col-start-1 col-end-13 pt-16 ">
							<KenyaMapIcon width="50"/>
							<Button
								type='submit'
								className='px-48 ml-auto question-section__submit-button'
								variant="submitButton"
							>
								{context.tr('next')}
							</Button>
						</div>
					</div>
				</section>
			</Form>
		</Formik>
	);
};
