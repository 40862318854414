import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Text } from './typography';
import { AnimationCurve1, CloudLg, CloudSm } from './svgs';

export const HeroSection = ({ blok }) => {
	const cloud1Ref =  useRef(null);
	const cloud2Ref =  useRef(null);
	const [offset, setOffset] = useState(0);
	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		if(cloud1Ref) {
			gsap.to(cloud1Ref.current, {
				right: '45%',
				scrollTrigger: {
					trigger: '.herosection',
					start: 'top bottom',
					end: 'bottom 30%',
					scrub: true,
				},
			});
		}

		if(cloud2Ref) {
			gsap.to(cloud2Ref.current, {
				right: '25%',
				scrollTrigger: {
					trigger: '.herosection',
					start: 'top bottom',
					end: 'bottom 30%',
					scrub: true,
				},
			});
		}

		const o = {offset: offset};
		const offsetAnimation = gsap.to(o, {
			offset: 150,
			scrollTrigger: {
				trigger: '.herosection',
				start: 'top center',
				onUpdate: (trigger) => { 
					setOffset(o.offset * trigger.progress); 
				},
			},
		});


		return () => {
			gsap.killTweensOf(cloud1Ref.current);
			gsap.killTweensOf(cloud2Ref.current);
			offsetAnimation.kill();
		};

	}, []);

	return <section className="relative grid grid-cols-12 mx-auto my-64 herosection max-w-grid w-92p">
		<div className="flex col-start-1 col-end-8">
			<img src={blok.image.filename} alt={blok.title} className="mx-auto mt-auto max-h-95"/>
		</div>

		<div className="flex flex-col col-start-8 col-end-13 pb-24 pl-48 pr-20 justify-end text-primary">
			<div className="absolute top-220 right-40">
				<AnimationCurve1 text={`${blok?.animated_text || 'Lær mere'}!`} offset={offset}/>
			</div>
			<Text type="heading2" className="mb-40">{blok?.title || ''}</Text>
			<Text type="body1">{blok?.body || ''}</Text>
		</div>

		<div className="absolute w-128 h-124 right-10p top-36" ref={cloud1Ref}>
			<CloudSm />
		</div>
		<div className="absolute w-128 h-124 right-5p top-124" ref={cloud2Ref}>
			<CloudLg />
		</div>
	</section>;
};
